import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './app/store';
import App from './app/App';
import {TelegramWebApp} from 'react-telegram-webapp';
import {fetchPersonalData, fetchPersonalDataCreator, setBaseParams} from './app/profileSlice';
import {getRooms} from './features/Chats/chatSlice';
import {ToastContainer} from 'react-toastify';
import {API_BASE_URL} from './app/const';
import './i18n.ts';
import i18n from 'i18next';

const container = document.getElementById('root')!;
const root = createRoot(container);
const BASE_URL = API_BASE_URL;

async function validateQueryData(query_data: string) {
  const response = await fetch(`${BASE_URL}/users/validate_query/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({query_data})
  });

  const data = await response.json();
  const query_data_obj = query_data.split('&');
  
  //Set language
  i18n.changeLanguage(data.language);

  const hashDirty = query_data_obj.find((elem) => elem.includes('hash'));
  const hash = hashDirty?.split('=')[1];

  if (data.valid) sessionStorage.setItem('hash', hash && hash.length > 0 ? hash : '');

  //Open WebApp on a full screen
  window.Telegram.WebApp.expand();

  if (hash) {
    store.dispatch(setBaseParams({hash: hash, profile_type: data.profile_type, language: data.language}));

    store.dispatch(data.profile_type === 'default' ? fetchPersonalData(hash) : fetchPersonalDataCreator(hash));
    store.dispatch(getRooms());
  } else {
    console.log('no hash');
  }

  return data.valid;
}

root.render(
  <>
    <Provider store={store}>
      <TelegramWebApp validateHash={validateQueryData}>
        <>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ToastContainer autoClose={1000} />
        </>
      </TelegramWebApp>
    </Provider>
  </>
);
