import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Path} from '../../../app/const';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {selectedInterest, searchEvents} from './../eventsSlice';
import PageWrap from '../../../layout/PageWrap';
import WantedInterests from '../../../shared/WantedInterests';
import Button from '../../../shared/Button';
import styles from './AttendTheEventChangeParams.module.scss';
import RangeSliderSingle from '../../../shared/RangeSliderSingle';
import {useTranslation} from 'react-i18next';
import {selectLanguage} from '../../../app/profileSlice';

export default function AttendTheEventChangeParams() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [interests, setInterests] = useState<any>([]);
  const [selectedRadius, setSelectedRadius] = useState('5');
  const [enteredRadius, setenteredRadius] = useState('');
  const handleSelectedRadius = ({target: {value}}: any) => setSelectedRadius(value);
  const selectedInterests = useAppSelector(selectedInterest);
  const interestsNames: any = [];
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);

  selectedInterests.map((interest: any) => {
    interestsNames.push(interest.name);
  });

  return (
    <PageWrap
      mainClass={styles.root}
      title={t('ATTENDTHEEVENTCHANGEPARAMS.attend')}
      onClick={() => navigate(Path.Root)}
    >
      <>
        <p className='mt8 mb24'>{t('ATTENDTHEEVENTCHANGEPARAMS.what')}</p>

        {/* <label className='inputWrap mb16'>
            <span className='innerLabel'>Search radius</span>
            <select defaultValue={selectedRadius} onChange={handleSelectedRadius}>
              {radiusOptions.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </label>
          {selectedRadius === 'custom' && (
            <>
              <label className='inputWrap'>
                <input
                  type='number'
                  min={0}
                  max={50}
                  onChange={(e) => setenteredRadius(e.target.value)}
                  placeholder='Type here...'
                />
                <span className={styles.inputHelper}>km</span>
              </label>
              <span className='caption'>Max. 50 km</span>
            </>
          )} */}

        <RangeSliderSingle
          language={language}
          title={t('ATTENDTHEEVENTCHANGEPARAMS.search')}
          setFunction={setenteredRadius}
        />

        <div className={`${styles.select} mb32`}>
          <span className='inputLabel'>{t('ATTENDTHEEVENTCHANGEPARAMS.interests')}</span>
          <WantedInterests options={interests} />
        </div>

        <Button
          className={styles.btn}
          // disabled={interestsNames.length === 0}
          onClick={() =>
            dispatch(
              searchEvents({
                radius: enteredRadius ? +enteredRadius * 1000 : +selectedRadius * 1000,
                interests: interestsNames
              })
            ).then((res) => {
              if (res.type && res.type.includes('fulfilled')) {
                window.Telegram.WebApp.close();
              }
            })
          }
        >
          {t('BROWSEINTERESTS.send')}
        </Button>
      </>
    </PageWrap>
  );
}
