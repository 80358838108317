import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/Сounter/counterSlice';
import appReducer from './appSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import profileReducer from '../app/profileSlice';
import candidateReducer from '../features/MeetSomeone/searchSlice';
import chatReducer from '../features/Chats/chatSlice';
import interestsReducer from '../features/BrowseInterests/interestsSlice';
import eventsReducer from '../features/AttendTheEvent/eventsSlice';
import { history } from './const';
import { connectRouter } from 'connected-react-router'

export const store = configureStore({
  reducer: {
    app: appReducer,
    candidates: candidateReducer,
    chat: chatReducer,
    counter: counterReducer,
    events: eventsReducer,
    interests: interestsReducer,
    profileUser: profileReducer,
    routes: connectRouter(history)
  },

});

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
