import {A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {venueProfile} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import {ReactComponent as StarIcon} from '../../../assets/icons/star.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import mapImg from '../../../assets/images/map.png';
import 'swiper/css';
import styles from './VenueProfile.module.scss';
import {useTranslation} from 'react-i18next';

export default function VenueProfile() {
  const {images, name, rating, desc, location} = venueProfile;
  const {t} = useTranslation();

  return (
    <PageWrap mainClass={styles.root} title={t("VENUEPROFILE.venue")}>
      <Swiper className={styles.slider} modules={[A11y]} spaceBetween={8}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img className={styles.img} src={image} alt='Venue' />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.name}>
        <span className='title truncated'>{name}</span>
        <span className={styles.rating}>
          <StarIcon />
          {rating}
        </span>
      </div>

      <p className='mb16'>{desc}</p>

      <div className={styles.location}>
        <LocationIcon /> <span className='truncated details'>{location}</span>
      </div>
      <img className={styles.map} src={mapImg} alt='Map' />
    </PageWrap>
  );
}
