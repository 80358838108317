import PageWrap from '../../../layout/PageWrap';
import PeopleSlider from '../../../shared/PeopleSlider';
// import styles from './SearchResults.module.scss';
import {useTranslation} from 'react-i18next';
export default function SearchResults() {
  const {t} = useTranslation();

  return (
    <PageWrap title={t('SEARCHRESULTS.search')} goBack='/meet-someone/'>
      <PeopleSlider />
    </PageWrap>
  );
}
