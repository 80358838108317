import React, {useState, Component} from 'react';
import {Range, getTrackBackground} from 'react-range';
import styles from './RangeSliderSingle.module.scss';

interface RangeSliderSingleProps {
  setFunction?: any;
  title: string;
  language?: string;
}

class RangeSliderSingle extends Component<RangeSliderSingleProps> {
  constructor(props: RangeSliderSingleProps) {
    super(props);
  }

  state = {values: [5]};

  render() {
    //set interface for this.props.setFunction
    const {setFunction, title, language}: RangeSliderSingleProps = this.props;

    const kmVal = language === ('en' || 'ee') ? 'km' : 'км';

    return (
      <div className='mb-16'>
        <div className={styles.row}>
          <p className={styles.title}>{title}</p>
          <span className={styles.text}>
            {this.state.values[0].toFixed(0)} {kmVal}
          </span>
        </div>

        <Range
          step={1}
          min={1}
          max={50}
          values={this.state.values}
          onChange={(values) => {
            this.setState({values});
            //@ts-ignore
            this.props.setFunction(values[0].toFixed(0));
          }}
          renderTrack={({props, children}) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '100%',
                background: getTrackBackground({
                  values: this.state.values,
                  colors: ['#EC5B75', '#ccc'],
                  min: 1,
                  max: 50
                }),
                padding: '2px',
                borderRadius: '4px'
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({props}) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '28px',
                width: '28px',
                borderRadius: '50%',
                backgroundColor: '#FFF',
                boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12)'
              }}
            />
          )}
        />
      </div>
    );
  }
}
export default RangeSliderSingle;
