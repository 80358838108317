import React from 'react';
import styles from './Bot.module.scss';
import {ReactComponent as LogoIcon} from '../../assets/images/Logo.svg';
import {useTranslation} from 'react-i18next';

export default function Bot() {
  const {t} = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.head_bot}>
        <LogoIcon />
      </div>
      <div className={styles.bot}>
        <div className={styles.bot_info}>
          <b>{t('BOT.hi')}</b> <br />
          <br />
          {t('BOT.i')} <br />
          <br />
          {t('BOT.all')} <br />
          <br />
          {t('BOT.but')}
        </div>
        <div className={styles.start}>
          <button> {t('BOT.start')}</button>
        </div>
      </div>
    </div>
  );
}
