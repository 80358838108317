import {useEffect, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {interestedEvents, Path} from '../../app/const';
import PageWrap from '../../layout/PageWrap';
import WantedInterests from '../../shared/WantedInterests';
import Button from '../../shared/Button';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as Ongoing} from '../../assets/icons/ongoing.svg';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Location} from '../../assets/icons/location_icon.svg';
import {ReactComponent as Canceled} from '../../assets/icons/canceled.svg';
import styles from './EventCreator.module.scss';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {addEvent} from '../../features/AttendTheEvent/eventsSlice';
import {getListOfEvents, eventsList} from '../AttendTheEvent/eventsSlice';
import NoEvents from './NoEvents';
import 'react-toastify/dist/ReactToastify.css';
import {format} from 'date-fns';
import placeholderImg from '../../assets/icons/placeholder.svg';
import {API_BASE_URL} from '../../app/const';
import RangeSliderSingle from '../../shared/RangeSliderSingle';
import {useTranslation} from 'react-i18next';
import {ru, enUS} from 'date-fns/locale';
import {selectLanguage} from '../../app/profileSlice';
import {utcToZonedTime} from 'date-fns-tz';

export default function EventCreator() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const eventsArr = useAppSelector(eventsList);
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);
  const baseLanguage = language === ('en' || 'ee') ? enUS : ru;

  useEffect(() => {
    interestedEvents?.length ? navigate(Path.EventCreator + '?interested=true') : navigate(Path.EventCreator);
    dispatch(getListOfEvents());
  }, []);

  const [select, setSelect] = useState(false);
  const openSelect = () => setSelect(true);
  const closeSelect = () => setSelect(false);

  const [interests, setInterests] = useState([] as string[]);
  const [selectedRadius, setSelectedRadius] = useState('5');
  const handleSelectedRadius = ({target: {value}}: any) => setSelectedRadius(value);

  const handleBackBtn = () => {
    if (select) {
      closeSelect();
    } else if (searchParams.has('interested')) {
      navigate(Path.Root);
    } else navigate(-1);
  };

  return (
    <PageWrap
      mainClass={styles.root}
      headerButtonTitle={
        eventsArr?.active.length !== 0 || eventsArr?.recent.length !== 0 ? ` ${t('VENUES.add')} +` : ''
      }
      headerButtonFunction={() =>
        dispatch(addEvent()).then((res: any) => {
          if (res.type && res.type.includes('fulfilled')) {
            window.Telegram.WebApp.close();
          }
        })
      }
      goBack='/'
      title={t('EVENTCREATOR.events')}
      onClick={handleBackBtn}
    >
      {searchParams.has('interested') && (
        <>
          {eventsArr?.active.length > 0 && <p className={styles.event_type}>{t('EVENTCREATOR.active')}</p>}

          {eventsArr?.active.map((element: any, index: any) => (
            <Link key={index} className={styles.card} to={`${Path.EventCreatorDetails}/${element.id}`}>
              <img
                className={styles.cardImg}
                src={element?.picture ? `${API_BASE_URL}${element?.picture}` : placeholderImg}
                width='64px'
                height='64px'
                alt='Event'
              />

              <div className={styles.cardCol}>
                <h2 className={`${styles.cardTitle} truncated`}>{element?.name}</h2>
                <div className={`${styles.cardRow} truncated`}>
                  <div className={styles.cardText}>
                    <Location />
                    <Link to={`${Path.VenueDetails}/${element?.venue?.id}`}>
                      <span className={styles.cardVenue}>
                        <b>{element?.venue?.name} </b>
                      </span>
                    </Link>
                  </div>
                  {element?.status === 'ongoing' ? (
                    <div className={styles.cardText}>
                      <Ongoing />
                      <span className={styles.cardTime}>
                        <b>{t('ATTENDTHEEVENT.ongoing')}</b>
                      </span>
                    </div>
                  ) : element?.status === 'canceled' ? (
                    <div className={styles.cardText}>
                      <Canceled />
                      <span className={styles.cardTime}>
                        <b>{t('ATTENDTHEEVENT.canceled')}</b>
                      </span>
                    </div>
                  ) : (
                    <div className={styles.cardText}>
                      <Calendar />
                      <b>
                        {format(
                          new Date(utcToZonedTime(element.start_date, 'UTC')),
                          baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                          {locale: baseLanguage}
                        )}
                      </b>
                    </div>
                  )}
                </div>
                <ArrowIcon className={styles.cardArrow} />
              </div>
            </Link>
          ))}

          {eventsArr?.recent.length > 0 && <p className={styles.event_type}>{t('EVENTCREATOR.recent')}</p>}

          {eventsArr?.recent.map((element: any, index: any) => (
            <Link key={index} className={styles.card} to={`${Path.EventCreatorDetails}/${element.id}`}>
              <img
                className={styles.cardImg}
                src={element?.picture ? `${API_BASE_URL}${element?.picture}` : placeholderImg}
                width='64px'
                height='64px'
                alt='Event'
              />

              <div className={styles.cardCol}>
                <h2 className={`${styles.cardTitle} truncated`}>{element?.name}</h2>

                <div className={`${styles.cardRow} truncated`}>
                  <div className={styles.cardText}>
                    <Location />
                    <Link to={`${Path.VenueDetails}/${element?.venue?.id}`}>
                      <span className={styles.cardVenue}>
                        <b>{element?.venue?.name} </b>
                      </span>
                    </Link>
                  </div>
                  {element?.status === 'ongoing' ? (
                    <div className={styles.cardText}>
                      <Ongoing />
                      <span className={styles.cardTime}>
                        <b>{t('ATTENDTHEEVENT.ongoing')}</b>
                      </span>
                    </div>
                  ) : element?.status === 'canceled' ? (
                    <div className={styles.cardText}>
                      <Canceled />
                      <span className={styles.cardTime}>
                        <b>{t('ATTENDTHEEVENT.canceled')}</b>
                      </span>
                    </div>
                  ) : (
                    <div className={styles.cardText}>
                      <Calendar />
                      <b>
                        {format(
                          new Date(utcToZonedTime(element.start_date, 'UTC')),
                          baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                          {locale: baseLanguage}
                        )}
                      </b>
                    </div>
                  )}
                </div>
                <ArrowIcon className={styles.cardArrow} />
              </div>
            </Link>
          ))}
        </>
      )}

      {eventsArr?.active.length === 0 && eventsArr?.recent.length === 0 && (
        <NoEvents
          onClick={() =>
            dispatch(addEvent()).then((res: any) => {
              if (res.type && res.type.includes('fulfilled')) {
                window.Telegram.WebApp.close();
              }
            })
          }
        />
      )}

      {!searchParams.has('interested') &&
        (!select ? (
          <>
            <p className='mt8 mb24'>{t('ATTENDTHEEVENTCHANGEPARAMS.what')}</p>

            <div className={`${styles.select} mb32`}>
              <span className={styles.selectLabel}>{t('BROWSEINTERESTS.wanted')}</span>
              <button className={language === 'en' ? 'navBtn' : 'navBtn-lower'} type='button' onClick={openSelect}>
                <span className={`${!interests?.length ? `${styles.selectPlaceholder} ` : ''}label`}>
                  {!interests?.length && t('EVENTCREATOR.choose')}
                  {interests?.length <= 3
                    ? interests.join(', ')
                    : `${interests.slice(0, 3).join(', ')} + ${interests.length - 3}`}
                </span>
                <ArrowIcon />
              </button>
              <span className='caption'>{t('EVENTCREATOR.these')}</span>
            </div>

            {/* <label className='inputWrap mb16'>
              <span className='innerLabel'>Search radius</span>
              <select defaultValue={selectedRadius} onChange={handleSelectedRadius}>
                {radiusOptions.map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </label>

            {selectedRadius === 'custom' && (
              <>
                <label className='inputWrap'>
                  <input type='number' placeholder='Type here...' />
                  <span className={styles.inputHelper}>km</span>
                </label>
                <span className='caption'>Max. 50 km</span>
              </>
            )} */}

            <RangeSliderSingle
              language={language}
              title={t('ATTENDTHEEVENTCHANGEPARAMS.search')}
              setFunction={setSelectedRadius}
            />

            <Button className={styles.btn} to={Path.MatchingResultsEvent}>
              {t('BROWSEINTERESTS.send')}
            </Button>
          </>
        ) : (
          <WantedInterests options={interests} setOptions={setInterests} />
        ))}
    </PageWrap>
  );
}
