import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Path, radiusOptions} from '../../app/const';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import PageWrap from '../../layout/PageWrap';
import MissingData from '../../shared/MissingData';
import WantedInterests from '../../shared/WantedInterests';
import Button from '../../shared/Button';
import {selectProfileUser} from '../../app/profileSlice';
import {getBrowseInterests} from './interestsSlice';
import styles from './BrowseInterests.module.scss';
import {selectedInterest} from '../AttendTheEvent/eventsSlice';
import RangeSliderSingle from '../../shared/RangeSliderSingle';
import {useTranslation} from 'react-i18next';
import {selectLanguage} from '../../app/profileSlice';

export default function BrowseInterests() {
  const {is_anonymous} = useAppSelector(selectProfileUser);
  const dispatch = useAppDispatch();
  const state = useSelector((state) => state);
  const {t} = useTranslation();

  const [missingData, setMissingData] = useState(false);
  const skipMessage = () => setMissingData(false);

  useEffect(() => {
    setMissingData(is_anonymous);
  }, [is_anonymous]);

  const [select, setSelect] = useState(false);
  const openSelect = () => setSelect(true);
  const closeSelect = () => setSelect(false);

  const selectedInterests = useAppSelector(selectedInterest);
  const language = useAppSelector(selectLanguage);
  const interestsNames: any = [];

  selectedInterests.map((interest: any) => {
    interestsNames.push(interest.name);
  });

  const [selectedRadius, setSelectedRadius] = useState('5');
  const [enteresRadius, setEnteresRadius] = useState('');
  const handleSelectedRadius = ({target: {value}}: any) => setSelectedRadius(value);

  return (
    <PageWrap
      mainClass={styles.root}
      title={!select ? t('BROWSEINTERESTS.browse') : t('BROWSEINTERESTS.wanted')}
      goBack='/'
      onClick={select && closeSelect}
    >
      {missingData && <MissingData onClick={skipMessage} />}
      {!missingData && (
        <>
          {/* <p className='mt8 mb24'>What are you looking for today? </p>
          <label className='inputWrap mb16'>
            <span className='innerLabel'>Search radius</span>
            <select defaultValue={selectedRadius} onChange={handleSelectedRadius}>
              {radiusOptions.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </label>
          {selectedRadius === 'custom' && (
            <>
              <label className='inputWrap'>
                <input
                  type='number'
                  min={0}
                  max={50}
                  onChange={(e) => setEnteresRadius(e.target.value)}
                  placeholder='Type here...'
                />
                <span className={styles.inputHelper}>km</span>
              </label>
              <span className='caption'>Max. 50 km</span>
            </>
          )} */}

          <RangeSliderSingle
            language={language}
            title={t('ATTENDTHEEVENTCHANGEPARAMS.search')}
            setFunction={setEnteresRadius}
          />

          <div className={`${styles.select} mb32`}>
            <span className={styles.selectLabel}>{t('BROWSEINTERESTS.wanted')}</span>
            <WantedInterests />
          </div>

          <Button
            className={styles.btn}
            // disabled={interestsNames.length === 0}
            onClick={() =>
              dispatch(
                getBrowseInterests({
                  radius: enteresRadius ? +enteresRadius * 1000 : +selectedRadius * 1000,
                  interests: interestsNames
                })
              ).then((res) => {
                if (res.type && res.type.includes('fulfilled')) {
                  window.Telegram.WebApp.close();
                }
              })
            }
          >
            {t('BROWSEINTERESTS.send')}
          </Button>
        </>
      )}
    </PageWrap>
  );
}
