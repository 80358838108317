import {Link} from 'react-router-dom';
import {Path} from '../../../app/const';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check-2.svg';
import {ReactComponent as UnreadIcon} from '../../../assets/icons/sent.svg';
import {ReactComponent as ReadIcon} from '../../../assets/icons/read.svg';
import {User, LastMessage} from '../../../features/Chats/chatSlice';
import styles from './ChatPreview.module.scss';
import {format} from 'date-fns';
import anonym from '../../../assets/images/anonym.png';
import {useTranslation} from 'react-i18next';

interface ChatPreviewProps {
  id: number;
  className?: string;
  approved: boolean;
  status?: string;
  user: User;
  last_message: LastMessage | null;
  unread_count: number | null;
  loading: string | null;
}

export default function ChatPreview({
  id,
  className = '',
  last_message,
  loading,
  user,
  unread_count
}: ChatPreviewProps) {
  const {t} = useTranslation();
  return (
    last_message && (
      <Link className={`${styles.root}${className && ` ${className}`}`} to={`${Path.Chats}/${id}`}>
        <img
          className={styles.img}
          src={user.picture ? user.picture : anonym}
          alt={user.first_name ? user.first_name : 'Anonymous User'}
        />
        <div className={styles.inner}>
          <div className={styles.row}>
            <h2 className={`${styles.name} title`}>
              <span className='truncated'>{user.first_name ? user.first_name : t('CHATPREVIEW.anonymous')}</span>{' '}
              {user?.is_verified && <CheckIcon />}
            </h2>
            {last_message && (
              <span className={styles.time}>
                {last_message?.unread && <UnreadIcon />}
                {!last_message?.unread && <ReadIcon />}
                {format(new Date(last_message?.create_date), 'HH:mm')}
              </span>
            )}
          </div>
          <div className={styles.row}>
            {last_message && <span className={`${styles.message} truncated`}>{last_message.text}</span>}
            {!!unread_count && <span className={styles.new}>{unread_count}</span>}
          </div>
        </div>
      </Link>
    )
  );
}
