import Button from '../../../shared/Button';
import eventImg from '../../../assets/images/emptyEventsList.png';
import styles from './NoEvents.module.scss';
import {useTranslation} from 'react-i18next';


interface NoEventsProps {
  onClick: () => void;
}

export default function NoEvents({onClick}: NoEventsProps) {
  const {t} = useTranslation();
  return (
    <div className={styles.root}>
      <img className={styles.img} src={eventImg} width='343px' height='259px' alt='Venue' />
      <p className={styles.desc}>
        <b>{t("NOEVENTS.you")}</b>
        <br />
        {t("NOEVENTS.lets")}
      </p>
      <Button className={styles.btn} onClick={onClick}>
      {t("NOEVENTS.add")}
      </Button>
    </div>
  );
}
