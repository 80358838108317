import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Path} from '../../app/const';
import Button from '../Button';
import {ReactComponent as PeopleIcon} from '../../assets/icons/people.svg';
import {ReactComponent as PeopleRedIcon} from '../../assets/icons/people-red.svg';
import {ReactComponent as Like} from '../../assets/icons/like.svg';
import {ReactComponent as Unlike} from '../../assets/icons/unlike.svg';
import {ReactComponent as Mark} from '../../assets/icons/mark.svg';
import {
  singleEvent,
  getAttendedSingleEvent,
  attendedEvent,
  togleAsInterested,
  toggleAsAttended,
  deleteEvent,
  cancelEvent,
  checkInterestedPeople,
  checkInterestedPeopleByCreator
} from '../../features/AttendTheEvent/eventsSlice';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {Swiper, SwiperSlide} from 'swiper/react';
import {selectProfileType} from '../../app/profileSlice';
import {format} from 'date-fns';
import {A11y} from 'swiper';
import placeholderImg from '../../assets/icons/placeholder.svg';
import {ReactComponent as Ongoing} from '../../assets/icons/ongoing.svg';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Location} from '../../assets/icons/location_icon.svg';
import {useLocation, useParams} from 'react-router-dom';
import 'swiper/css';
import styles from './EventCard.module.scss';
import {API_BASE_URL} from '../../app/const';
import {useTranslation} from 'react-i18next';
import {selectLanguage} from '../../app/profileSlice';
import {ru, enUS} from 'date-fns/locale';
import {utcToZonedTime} from 'date-fns-tz';

export default function EventCard({className = '', marked, eventInfo, uuidPath}: any) {
  const [interested, setInterested] = useState(false);
  const [attended, setAttended] = useState(false);
  const [attendedQuantity, setAttendedQuantity] = useState<any>(0);
  const {eventId} = useParams();
  const {pathname} = useLocation();
  const selectedEventData = useAppSelector(singleEvent);
  const attendedSingleEvent = useAppSelector(attendedEvent);
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);
  const baseLanguage = language === ('en' || 'ee') ? enUS : ru;

  let eventData = eventInfo;

  switch (pathname) {
    case `/event-creator/event-details/${eventId}`:
      eventData = selectedEventData;
      break;
    case `/attend-the-event/event-details/${eventId}`:
      eventData = attendedSingleEvent;
      break;
    case `/attend-the-event/matching-results`:
      eventData = eventInfo;
      break;
    // default:
    //   eventData = eventInfo;
  }

  let eventPicture = eventData && eventData?.pictures ? eventData.pictures : [eventData?.picture];

  const dispatch = useAppDispatch();
  const profile_type = useAppSelector(selectProfileType);
  // const uuid = search && search.split('&')[0].split('=')[1];
  // const type = search && search.split('&')[1].split('=')[1];
  const id = pathname.split('/')[3];

  useEffect(() => {
    profile_type === 'default' &&
      pathname !== '/attend-the-event/matching-results' &&
      dispatch(getAttendedSingleEvent(id));
  }, [profile_type]);

  useEffect(() => {
    setInterested(eventData?.is_interested);
    setAttended(eventData?.is_attended);
    setAttendedQuantity(eventData?.total_attendees);
  }, [eventData]);

  const endDate = eventData?.end_date && new Date(eventData?.end_date);
  const startDate = eventData?.start_date && new Date(eventData?.start_date);
  const checkRequest = profile_type === 'creator' ? checkInterestedPeopleByCreator : checkInterestedPeople;

  return (
    <>
      <Swiper className={styles.slider} modules={[A11y]} spaceBetween={8}>
        {eventPicture?.length ? (
          eventPicture?.map((image: any, index: any) => (
            <SwiperSlide key={index}>
              <img
                className={styles.img}
                src={image ? (image?.includes('https://') ? image : `${API_BASE_URL}/${image}`) : placeholderImg}
                alt='Event'
              />
            </SwiperSlide>
          ))
        ) : (
          <img className={styles.img} src={placeholderImg} alt='Placeholder' />
        )}
      </Swiper>

      <div className={`${styles.info} eventCardInfo`}>
        <div className={styles.name}>
          <span className='title'>{eventData && eventData.name && eventData.name}</span>
        </div>

        <div className={`${styles.details} truncated`}>
          <div className={styles.row}>
            <Location />
            <div>
              {' '}
              <Link
                className={styles.venue}
                to={
                  profile_type === 'default'
                    ? `${Path.VenueDetails}/${eventData && eventData.venue && eventData.venue.id}`
                    : Path.EventCreatorVenueProfile
                }
              >
                {eventData && eventData.venue && eventData.venue.name}{' '}
              </Link>
            </div>
          </div>
          <div className={styles.row}>
            {eventData?.status === 'ongoing' ? <Ongoing /> : <Calendar />}

            {(eventData?.status === 'canceled' || eventData?.status === 'completed') && eventData?.end_date && (
              <b>
                {format(utcToZonedTime(endDate, 'UTC'), baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm', {
                  locale: baseLanguage
                })}
              </b>
            )}

            {eventData?.status === 'ongoing' &&
              (!interested ? (
                <span className={styles.time}>
                  <b>{t('EVENTCARD.ongoing')}</b>
                </span>
              ) : (
                <>
                  <p className='mr4'> {t('EVENTCARD.ongoing')}</p>{' '}
                  {endDate && (
                    <b>
                      {format(
                        utcToZonedTime(endDate, 'UTC'),
                        baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                        {
                          locale: baseLanguage
                        }
                      )}
                    </b>
                  )}
                  {eventData?.end_date && (
                    <b>
                      {format(
                        new Date(utcToZonedTime(eventData?.end_date, 'UTC')),
                        baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                        {locale: baseLanguage}
                      )}
                    </b>
                  )}
                </>
              ))}
            {/* If status === 'upcoming' and event doesnt marked we show just status , else just date */}
            {eventData?.status === 'upcoming' && (
              <>
                {' '}
                <p className='mr-3'>{t('EVENTCARD.on')}</p>
                <b>
                  {startDate && (
                    <b>
                      {format(
                        utcToZonedTime(startDate, 'UTC'),
                        baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                        {locale: baseLanguage}
                      )}
                    </b>
                  )}
                </b>
              </>
            )}
          </div>
          <div className={styles.name}>
            <span
              className={
                eventData?.total_attendees >= eventData?.max_attendees && eventData?.max_attendees !== 0
                  ? styles.people__red
                  : styles.people
              }
              aria-label='People amount'
            >
              {attended ? <PeopleRedIcon /> : <PeopleIcon />}
              {eventData && eventData?.max_attendees !== 0
                ? `${attendedQuantity}/${eventData?.max_attendees}`
                : `${attendedQuantity}`}
            </span>
          </div>
        </div>

        <p className={`${styles.description} my12`}>{eventData?.description}</p>
        {eventData?.interest?.length > 0 && (
          <div className={styles.tagList}>
            {eventData?.interest.map((tag: any, index: any) => (
              <span key={index} className={styles.tag}>
                {tag?.name}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className='eventCardFooter'>
        {/* {eventData?.status === 'ongoing' && profile_type === 'default' && (
          <>
            <Button className='mt16'>Scan QR-code</Button>
            <Button className='mt16' outlined to={Path.AttendingPeople}>
              View attending people
            </Button>
          </>
        )} */}

        {eventData?.status === 'ongoing' && profile_type === 'creator' && (
          <>
            {/* <button className={styles.buyQRCodes}>
              Buy more QR-codes <ArrowIcon className={styles.cardArrow} />
            </button>
            <p className={styles.QRCodesSold}>154 of 200 purchased QR-codes left</p> */}
            {eventData && (eventData.interesed_people > 0 || eventData.total_attendees > 0) && (
              <Button
                className='mt16'
                outlined
                onClick={() => {
                  dispatch(checkRequest(eventData?.id)).then((res: any) => {
                    if (res.type && res.type.includes('fulfilled')) {
                      window.Telegram.WebApp.close();
                    }
                  });
                }}
              >
                {t('EVENTCARD.view1')}
              </Button>
            )}
            {eventData?.status !== 'canceled' && eventData?.status !== 'completed' && (
              <Button
                className={styles.cancel_event}
                outlined
                onClick={() =>
                  dispatch(cancelEvent(eventData?.id)).then((res) => {
                    if (res.type && res.type.includes('fulfilled')) {
                      window.Telegram.WebApp.close();
                    }
                  })
                }
              >
                {t('EVENTCARD.cancel')}
              </Button>
            )}
            <Button
              className={styles.cancel_event}
              outlined
              onClick={() =>
                dispatch(deleteEvent(eventData?.id)).then((res) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    window.Telegram.WebApp.close();
                  }
                })
              }
            >
              {t('EVENTCARD.delete')}
            </Button>
          </>
        )}
        {eventData?.status !== 'ongoing' && profile_type === 'creator' && (
          <>
            {/* <button className={styles.buyQRCodes}>
              Buy more QR-codes <ArrowIcon className={styles.cardArrow} />
            </button>
            <p className={styles.QRCodesSold}>200 purchased QR-codes</p> */}

            {eventData && (eventData.interesed_people > 0 || eventData.total_attendees > 0) && (
              <Button
                className='mt16'
                outlined
                onClick={() => {
                  dispatch(checkRequest(eventData?.id)).then((res: any) => {
                    if (res.type && res.type.includes('fulfilled')) {
                      window.Telegram.WebApp.close();
                    }
                  });
                }}
              >
                {t('EVENTCARD.view1')}
              </Button>
            )}

            {eventData?.status !== 'canceled' && eventData?.status !== 'completed' && (
              <Button
                className={styles.cancel_event}
                outlined
                onClick={() =>
                  dispatch(cancelEvent(eventData?.id)).then((res) => {
                    if (res.type && res.type.includes('fulfilled')) {
                      window.Telegram.WebApp.close();
                    }
                  })
                }
              >
                {t('EVENTCARD.cancel')}
              </Button>
            )}
            <Button
              className={styles.cancel_event}
              outlined
              onClick={() =>
                dispatch(deleteEvent(eventData?.id)).then((res) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    window.Telegram.WebApp.close();
                  }
                })
              }
            >
              {t('EVENTCARD.delete')}
            </Button>
          </>
        )}

        {/* {eventData?.status !== 'ongoing' && profile_type === 'default' && ( */}
        {profile_type === 'default' && (
          <>
            <Button
              className='mt16'
              outlined={attended}
              disabled={eventData?.total_attendees === eventData?.max_attendees && eventData?.max_attendees !== 0}
              onClick={() => {
                dispatch(toggleAsAttended(eventData?.id)).then((res: any) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    setAttended(!attended);
                    setAttendedQuantity(attended ? attendedQuantity - 1 : attendedQuantity + 1);
                  }
                });
              }}
            >
              {attended && <Mark className='mr-10' />}
              {attended ? t('ATTENDTHEEVENTCHANGEPARAMS.attending') : t('ATTENDTHEEVENTCHANGEPARAMS.attend')}
            </Button>

            {eventData && (
              <div className={styles.row}>
                <Button
                  className='mt16'
                  outlined
                  onClick={() => {
                    dispatch(checkRequest(eventData?.id)).then((res: any) => {
                      if (res.type && res.type.includes('fulfilled')) {
                        window.Telegram.WebApp.close();
                      }
                    });
                  }}
                >
                  {t('EVENTCARD.view2')}
                </Button>
                <Button
                  className={`${styles.like} mt16 ml-16`}
                  outlined
                  onClick={() => {
                    setInterested((prev: any) => !prev);
                    dispatch(togleAsInterested(eventData?.id)).then((res) => {
                      if (res.type && res.type.includes('fulfilled')) {
                        pathname === `/attend-the-event/event-details/${eventId}` &&
                          dispatch(getAttendedSingleEvent(id));
                        // ? dispatch(getSearchedData({id: uuid, type: type === 'matching' ? 'matching' : 'other'}))
                      }
                    });
                  }}
                >
                  {interested ? <Unlike /> : <Like />}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
