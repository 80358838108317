import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {updateEvent} from '../../EventCreator/eventsAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './EventChangeNumber.module.scss';
import {getSingleEvent, singleEvent, updateEventData} from '../../../features/AttendTheEvent/eventsSlice';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export default function EventChangeNumber() {
  const {venueId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [venue, setVenue] = useState({contact_number: ''});
  const [phone, setPhone] = useState('');
  const [sms, setSms] = useState('');
  const handleInput = (setter: any) => (event: any) => setter(event.target.value);
  const {pathname} = useLocation();
  const eventId = pathname.split('/')[3];
  const currentEvent = useAppSelector(singleEvent);
  const {t} = useTranslation();

  useEffect(() => {
    !currentEvent && getSingleEvent(eventId);
  }, []);

  useEffect(() => {
    venue?.contact_number && setPhone(venue.contact_number);
  }, [venue]);

  const sendVenueNumber = () => {
    if (step === 1) setStep(2);
    if (step === 2) {
      venueId && updateEvent(venueId, {contact_number: phone}, setLoading, setVenue, navigate(-1));
    }
  };

  return (
    <PageWrap mainClass={styles.root} title={t('EVENTCHANGENUMBER.change')} goBack={-1}>
      <p className='mb24'>{t('EVENTCHANGENUMBER.enter')}</p>
      <label className='inputWrap mt24'>
        <span className='inputLabel'>{t('EVENTCHANGENUMBER.contact')}</span>
        <input
          type='tel'
          placeholder={t('EVENTCHANGENUMBER.type')}
          defaultValue={currentEvent?.contact_number}
          onChange={handleInput(setPhone)}
        />
      </label>
      {step === 2 && (
        <label className='inputWrap mt16'>
          <span className='inputLabel'>{t('EVENTCHANGENUMBER.sms')}</span>
          <input type='number' placeholder={t('EVENTCHANGENUMBER.type')} value={sms} onChange={handleInput(setSms)} />
        </label>
      )}

      <Button
        className='my32'
        disabled={loading || !phone || phone === venue?.contact_number || (step === 2 && !sms)}
        onClick={sendVenueNumber}
      >
        {t('EVENTCHANGENUMBER.continue')}
      </Button>

      {step === 2 && (
        <Button className={styles.resendBtn} outlined disabled={loading}>
          {t('EVENTCHANGENUMBER.resend')}
        </Button>
      )}
    </PageWrap>
  );
}
