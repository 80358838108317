import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { setLanguage } from './appSlice';
import { API_BASE_URL, HeadersFormData } from './const';
import { RootState } from './store';

export interface Interest {
  id: number;
  name: string;
}
export interface PersonalInfoInterface {
  loading: string;
  error: string | undefined;
  isBlockShown: boolean;
  hash: string | null;
  profile_type: 'default' | 'creator' | "unset";
  language: "en" | "ru" | "ee";
  creator: {
    id: number | null,
    full_name: string | null,
    picture: string | null,
    phone_number: string | null,
    card_number: string | null,
    is_verified: boolean | null,
    ignore_setup: boolean | null,
    user: number | null
  }
  user:
  {
    is_anonymous: boolean;
    interests: Interest[];


    profile: {
      id: number | null;
      first_name: string | null;
      gender: string | null;
      birth_date: string | null;
      bio: string | null;
      picture: string | null;
      user: number | null;
      city: string | null;
    };
    subscription: {
      id: number | null;
      type: 'qr' | 'buy' | any;
      buy_date: string | null;
    } | null;
    location: { longitude: number | null; latitude: number | null };
  }
  | any;
}

export const initialState: PersonalInfoInterface = {
  loading: '',
  error: '',
  isBlockShown: false,
  hash: null,
  profile_type: 'unset',
  language: 'en',

  creator: {
    id: null,
    full_name: null,
    picture: null,
    phone_number: null,
    card_number: null,
    is_verified: null,
    ignore_setup: null,
    user: null
  },
  user: {
    is_anonymous: true,
    interests: [],
    profile: {
      id: null,
      first_name: null,
      gender: null,
      birth_date: null,
      bio: null,
      picture: null,
      user: null,
      city: null
    },
    subscription: {
      id: null,
      type: null,
      buy_date: null
    },
    location: { longitude: null, latitude: null }
  }
};

export const fetchPersonalData = createAsyncThunk('GET_PERSONAL_INFO', async (hash?: string) => {
  const response = await axios.get(`${API_BASE_URL}/profiles/my/`);
  return response.data;
});

export const fetchPersonalDataCreator = createAsyncThunk('GET_PERSONAL_CREATOR_INFO', async (hash?: string) => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/`);
  return response.data;
});

export const patchPersonalData = createAsyncThunk('PATCH_PERSONAL_INFO', async (requestData: any) => {
  const response = await axios.patch(`${API_BASE_URL}/profiles/my/`, requestData.body,);
  return response.data;
});

export const patchPersonalDataCreator = createAsyncThunk('PATCH_PERSONAL_CREATOR_INFO', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/`, body.profileInfo);
  return response.data;
});


export const patchAvatar = createAsyncThunk('PATCH_AVATAR', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/profiles/my/image/`, body, {
    headers: HeadersFormData
  });
  return response.data;
});

export const patchAvatarCreator = createAsyncThunk('PATCH_AVATAR_CREATOR', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/image/`, body, {
    headers: HeadersFormData
  });
  return response.data;
});

export const deleteAccount = createAsyncThunk('DELETE_ACCOUNT', async () => {
  const response = await axios.post(`${API_BASE_URL}/users/request-remove/`, {},);
  return response.data;
});

export const switchToCreator = createAsyncThunk('SWITCH_TO_CREATOR', async () => {
  const response = await axios.post(`${API_BASE_URL}/users/switch-to-creator/`, {},);
  return response.data;
});

export const switchToUser = createAsyncThunk('SWITCH_TO_USER', async () => {
  const response = await axios.post(`${API_BASE_URL}/users/switch-to-default/`, {},);
  return response.data;
});

export const getFreeSubscription = createAsyncThunk('GET_FREE_SUBSCRIPTION', async () => {
  const response = await axios.post(`${API_BASE_URL}/profiles/my/subscription/`, {},);
  return response.data;
});


export const changeLocation = createAsyncThunk('CHANGE_LOCATION', async () => {
  const response = await axios.post(`${API_BASE_URL}/profiles/my/location/`, {},);
  return response.data;
});

export const changeLanguage = createAsyncThunk('CHANGE_LANGUAGE', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/users/user/`, body,);
  return response.data;
});

export const setupPhone = createAsyncThunk('SRTUP_PHONE', async () => {
  const response = await axios.post(`${API_BASE_URL}/profiles/my/setup-phone/`, {},);
  return response.data;
});


const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setBaseParams(state: any, action: PayloadAction<any>) {
      state.hash = action.payload.hash;
      state.profile_type = action.payload.profile_type;
      state.language = action.payload.language;
    },
    setBottomBoxForIphone(state: any, action: PayloadAction<boolean>) {
      state.isBlockShown = action.payload;
    },


  },
  extraReducers(builder) {
    builder

      //GET PERSONAL INFO REQUEST
      .addCase(fetchPersonalData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchPersonalData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchPersonalData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //PATCH BOT LANGUAGE

      .addCase(changeLanguage.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(changeLanguage.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.language = action.payload.language;

      })
      .addCase(changeLanguage.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET PERSONAL CREATOR INFO REQUEST
      .addCase(fetchPersonalDataCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchPersonalDataCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.creator = action.payload;
      })
      .addCase(fetchPersonalDataCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE PERSONAL INFO REQUEST

      .addCase(patchPersonalData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchPersonalData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(patchPersonalData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE PERSONAL CREATOR INFO REQUEST

      .addCase(patchPersonalDataCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchPersonalDataCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(patchPersonalDataCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE AVATAR REQUEST

      .addCase(patchAvatar.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchAvatar.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user.profile.picture = action.payload.image;
      })
      .addCase(patchAvatar.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE AVATAR CREATOR REQUEST

      .addCase(patchAvatarCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchAvatarCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.creator.picture = `${API_BASE_URL}${action.payload.image}`;
      })
      .addCase(patchAvatarCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //DELETE ACCOUNT REQUEST
      .addCase(deleteAccount.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //SWITCH TO CREATOR 
      .addCase(switchToCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(switchToCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(switchToCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SWITCH TO USER

      .addCase(switchToUser.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(switchToUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(switchToUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET FREE SUBSCRIPTION

      .addCase(getFreeSubscription.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getFreeSubscription.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(getFreeSubscription.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )

      //CHANGE LOCATION

      .addCase(changeLocation.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(changeLocation.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(changeLocation.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )


      //SETUP PHONE

      .addCase(setupPhone.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(setupPhone.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(setupPhone.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )






  }
});

export const selectProfileUser = (state: RootState) => state.profileUser.user;
export const selectProfileCreator = (state: RootState) => state.profileUser.creator;
export const selectLoading = (state: RootState) => state.profileUser.loading;
export const selectAllProfileInfo = (state: RootState) => state.profileUser;
export const selectLanguage = (state: RootState) => state.profileUser.language;


export const selectHash = (state: RootState) => state.profileUser.hash;
export const selectProfileType = (state: RootState) => state.profileUser.profile_type;

export const { setBaseParams, setBottomBoxForIphone } = profileSlice.actions

export default profileSlice.reducer;
