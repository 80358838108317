import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getVenue, updateVenue} from '../venuesAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './VenueChangeNumber.module.scss';
import {useTranslation} from 'react-i18next';

export default function VenueChangeNumber() {
  const {venueId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [venue, setVenue] = useState({contact_number: ''});
  const [phone, setPhone] = useState('');
  const [sms, setSms] = useState('');
  const handleInput = (setter: any) => (event: any) => setter(event.target.value);
  const {t} = useTranslation();

  useEffect(() => {
    venueId && getVenue(venueId, setVenue);
  }, []);

  useEffect(() => {
    venue?.contact_number && setPhone(venue.contact_number);
  }, [venue]);

  const sendVenueNumber = () => {
    if (step === 1) setStep(2);
    if (step === 2) {
      venueId && updateVenue(venueId, {contact_number: phone}, setLoading, setVenue, navigate(-1));
    }
  };

  return (
    <PageWrap mainClass={styles.root} title={t('VENUECHANGENUMBER.change')} goBack={-1}>
      <p className='mb24'>{t('VENUECHANGENUMBER.enter')}</p>
      <label className='inputWrap mt24'>
        <span className='inputLabel'>{t('VENUECHANGENUMBER.contact')}</span>
        <input type='tel' placeholder={t('VENUECHANGENUMBER.type')} value={phone} onChange={handleInput(setPhone)} />
      </label>
      {step === 2 && (
        <label className='inputWrap mt16'>
          <span className='inputLabel'>{t('VENUECHANGENUMBER.sms')}</span>
          <input type='number' placeholder={t('VENUECHANGENUMBER.type')} value={sms} onChange={handleInput(setSms)} />
        </label>
      )}

      <Button
        className='my32'
        disabled={loading || !phone || phone === venue?.contact_number || (step === 2 && !sms)}
        onClick={sendVenueNumber}
      >
        {t('VENUECHANGENUMBER.continue')}
      </Button>

      {step === 2 && (
        <Button className={styles.resendBtn} outlined disabled={loading}>
          {t('VENUECHANGENUMBER.resend')}
        </Button>
      )}
    </PageWrap>
  );
}
