import {A11y, Navigation} from 'swiper';
import {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {getSearchedData, matchedEvents} from '../../features/AttendTheEvent/eventsSlice';
import {Swiper, SwiperSlide} from 'swiper/react';
import EventCard from '../EventCard';
import 'swiper/css';
import styles from './EventSlider.module.scss';
import {useLocation} from 'react-router-dom';
import {selectHash} from '../../app/profileSlice';
import SlideCounter from '../SlideCounter';
import 'swiper/css/navigation';

export default function EventSlider() {
  const {search} = useLocation();
  const eventsArr = useAppSelector(matchedEvents);
  const dispatch = useAppDispatch();
  const uuid = search.split('&')[0].split('=')[1];
  const type = search.split('&')[1].split('=')[1];
  const hash = useAppSelector(selectHash);
  useEffect(() => {
    hash && dispatch(getSearchedData({id: uuid, type: type === 'matching' ? 'matching' : 'other'}));
  }, [hash]);

  return (
    <div className={styles.root}>
      <Swiper className={styles.slider} modules={[A11y, Navigation]} navigation spaceBetween={8}>
        {eventsArr?.length > 0 &&
          eventsArr?.map((result: any, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              {({isActive}) => (
                <>
                  <EventCard uuidPath={`uuid=${uuid}&type=${type}`} className={styles.card} eventInfo={result} />

                  <SlideCounter length={eventsArr.length} index={index} isActive={isActive} />
                </>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
