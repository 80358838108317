import {Navigate, Route, Routes} from 'react-router-dom';
import axios from 'axios';
import {useState, CSSProperties} from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

import ErrorBoundary from '../shared/ErrorBoundary';
import {store} from './store';
import {Path} from './const';
import MainMenu from '../features/MainMenu';
import Profile from '../features/Profile';
import Counter from '../features/Сounter';
import ChangePhoto from '../features/Profile/ChangePhoto';
import EditInfo from '../features/Profile/EditInfo';
import ProfilePreview from '../features/Profile/ProfilePreview';
import BotLanguage from '../features/Profile/BotLanguage';
import SubscriptionDetails from '../features/SubscriptionDetails';
import MeetSomeone from '../features/MeetSomeone';
import SearchResults from '../features/MeetSomeone/SearchResults';
import BrowseInterests from '../features/BrowseInterests';
import InterestSearchResults from '../features/BrowseInterests/InterestSearchResults';
import AttendTheEvent from '../features/AttendTheEvent';
import AttendTheEventChangeParams from '../features/AttendTheEvent/AttendTheEventChangeParams';
import EventCreator from '../features/EventCreator';
import EventDetails from '../features/AttendTheEvent/EventDetails';
import EventDetailsCreator from '../features/EventCreator/EventDetails';
import EventPeople from '../features/AttendTheEvent/EventPeople';
import EventEdit from '../features/EventCreator/EventEdit';
import CreateProfile from '../features/Profile/CreateProfile';
import EventCreatorVenueProfile from '../features/EventCreator/VenueProfile';
import EventPeopleResults from '../features/AttendTheEvent/EventPeopleResults';
import MatchingResults from '../features/AttendTheEvent/MatchingResults';
import OtherResults from '../features/AttendTheEvent/OtherResults';
import VenueProfile from '../features/AttendTheEvent/VenueProfile';
import Chats from '../features/Chats';
import Chat from '../features/Chats/Chat';
import Bot from '../features/Bot/Bot';
import QrCode from '../features/QrCode/QrCode';
import UserProfile from '../features/Chats/UserProfile';
import CreatorMode from '../shared/CreatorMode';
import Venues from '../features/Venues';
import VenueDetails from '../features/Venues/VenueDetails';
import VenueEdit from '../features/Venues/VenueEdit';
import VenueRemove from '../features/Venues/VenueRemove';
import VenueChangeNumber from '../features/Venues/VenueChangeNumber';
import EventsChangeNumber from '../features/AttendTheEvent/EventChangeNumber/EventChangeNumber';
import VenuePhotos from '../features/Venues/VenuePhotos';
import '../assets/styles/main.scss';
import {toast} from 'react-toastify';
import {API_BASE_URL} from './const';


export default function App() {
  const [loader, setLoader] = useState(false);

  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red'
  };

  axios.interceptors.request.use((config: any) => {
    const hash = store.getState().profileUser?.hash || sessionStorage.getItem('hash');
    config.baseURL = API_BASE_URL;

    //LOADER SHOULD BE SHOWN ONLY ON PAGES WHERE IT'S NOT EXCEPTED
    // !exceptLinks.includes(config.url.split('=')[0]) && setLoader(true);

    if (hash) {
      config.headers.Authorization = `Hash ${hash}`;
      return config;
    }
  });

  axios.interceptors.response.use(
    (response) => {
      setLoader(false);
      return response;
    },
    (error) => {
      setLoader(false);
      if (error.response.status === 500) {
        toast.error('Server error. Please try later!', {
          toastId: 'error 500'
        });
      } else {
        toast.error(error.response?.data?.detail, {
          toastId: 'error'
        });
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <ErrorBoundary>
        <Routes>
          <Route path={Path.Root} element={<MainMenu />} />
          <Route path={Path.Profile} element={<Profile />} />
          <Route path={Path.Bot} element={<Bot />} />
          <Route path={Path.QrCode} element={<QrCode />} />
          <Route path={Path.ChangeProfilePhoto} element={<ChangePhoto />} />
          <Route path={Path.EditPersonalInformation} element={<EditInfo />} />
          <Route path={Path.CreatePersonalInformation} element={<CreateProfile />} />
          <Route path={Path.ProfilePreview} element={<ProfilePreview />} />
          <Route path={Path.BotLanguage} element={<BotLanguage />} />
          <Route path={Path.SubscriptionDetails} element={<SubscriptionDetails />} />
          <Route path={Path.MeetSomeone} element={<MeetSomeone />} />
          <Route path={Path.SearchResults} element={<SearchResults />} />
          <Route path={Path.BrowseInterests} element={<BrowseInterests />} />
          <Route path={Path.MatchingResultsInterest} element={<InterestSearchResults />} />
          <Route path={Path.AttendTheEvent} element={<AttendTheEvent />} />
          <Route path={Path.AttendTheEventChangeParams} element={<AttendTheEventChangeParams />} />
          <Route path={Path.EventCreator} element={<EventCreator />} />
          <Route path={`${Path.EventDetails}/:eventId`} element={<EventDetails />} />

          <Route path={`${Path.EventCreatorDetails}/:eventId`} element={<EventDetailsCreator />} />
          <Route path={`${Path.EventCreatorEdit}/:eventId`} element={<EventEdit />} />
          <Route path={Path.EventCreatorVenueProfile} element={<EventCreatorVenueProfile />} />
          <Route path={Path.AttendingPeople} element={<EventPeople />} />
          <Route path={Path.AttendingPeopleResults} element={<EventPeopleResults />} />
          <Route path={Path.MatchingResultsEvent} element={<MatchingResults />} />
          {/* <Route path={Path.MatchingResultsInterest} element={<MatchingResults />} /> */}
          <Route path={Path.OtherResults} element={<OtherResults />} />
          <Route path={Path.VenueProfile} element={<VenueProfile />} />
          <Route path={Path.InterestedPeople} element={<EventPeople interested />} />
          <Route path={Path.InterestedPeopleResults} element={<EventPeopleResults interested />} />
          <Route path={`${Path.InterestedPeopleResultsCreator}/:eventID`} element={<EventPeopleResults interested />} />
          <Route path={Path.Chats} element={<Chats />} />
          <Route path={`${Path.Chats}/:chatId`} element={<Chat />} />
          <Route path={`${Path.UserProfile}/:userId`} element={<UserProfile />} />
          <Route path='counter' element={<Counter />} />
          <Route path={`${Path.CreatorMode}`} element={<CreatorMode />} />
          <Route path={Path.VenuesCreator} element={<Venues />} />
          <Route path={`${Path.VenueDetails}/:venueId`} element={<VenueDetails />} />
          <Route path={`${Path.VenueEdit}/:venueId`} element={<VenueEdit />} />
          <Route path={`${Path.VenueChangeNumber}/:venueId`} element={<VenueChangeNumber />} />
          <Route path={`${Path.EventsChangeNumber}/:venueId`} element={<EventsChangeNumber />} />
          <Route path={`${Path.VenuePhotos}/:venueId`} element={<VenuePhotos />} />
          <Route path={`${Path.EventsPhotos}/:eventId`} element={<VenuePhotos />} />
          <Route path={`${Path.VenueRemove}/:venueId`} element={<VenueRemove />} />
          <Route path={`${Path.EventsRemove}/:eventId`} element={<VenueRemove />} />
          <Route path='*' element={<Navigate to={Path.Root} replace />} />
        </Routes>

        {loader && (
          <div className='loader-container'>
            <FadeLoader className='loader' color='#ec5b75' cssOverride={override} />
          </div>
        )}
      </ErrorBoundary>
    </>
  );
}
