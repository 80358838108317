import {useNavigate} from 'react-router-dom';
import Header from '../Header';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import styles from './PageWrap.module.scss';
import {useEffect, useState, useRef} from 'react';

interface PageWrapProps {
  children: any;
  header?: boolean;
  mainClass?: string;
  title?: string;
  onClick?: (() => any) | any;
  stateClear?: (() => any) | any;
  headerButtonTitle?: string;
  icon?: any;
  headerButtonFunction?: (() => any) | any;
  goBack?: any;
  showArrow?: boolean;
}

export default function PageWrap({
  children,
  header,
  mainClass = '',
  title,
  stateClear,
  headerButtonTitle,
  headerButtonFunction,
  icon,
  onClick,
  showArrow = true,
  goBack
}: PageWrapProps) {
  const navigate = useNavigate();
  let isInitiallyVisible = false;
  const divRef = useRef<null | HTMLDivElement>(null);

  const [isKeyboardVisible, setKeyboardVisible] = useState(isInitiallyVisible);

  useEffect(() => {
    window.visualViewport &&
      window.visualViewport.addEventListener('resize', () => {
        setKeyboardVisible(!isKeyboardVisible);
      });
  }, [isKeyboardVisible]);

  return (
    <>
      {header && <Header />}
      {title && (
        <header className={styles.header}>
          {showArrow && (
            <button
              className={`${styles.back} hover`}
              type='button'
              aria-label='Back button'
              onClick={
                onClick
                  ? onClick
                  : () => {
                      navigate(goBack ? goBack : '/');
                      stateClear && stateClear();
                    }
              }
            >
              <ArrowIcon className={styles.arrow} />
            </button>
          )}
          <h1 className='truncated'>{title}</h1>
          {(headerButtonTitle || icon) && (
            <button className={styles.headerBtn} type='button' onClick={headerButtonFunction}>
              {headerButtonTitle && <span>{headerButtonTitle}</span>}
              {icon}
            </button>
          )}
        </header>
      )}
      <main className={`${styles.main}${mainClass && ` ${mainClass}`}`}>
        {children}
        <div className='bottom-box'></div>
        {isKeyboardVisible && navigator.platform === 'iPhone' && (
          <div ref={divRef} className='up_content' style={{marginTop: 270}}></div>
        )}
      </main>
    </>
  );
}
