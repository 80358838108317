import axios from 'axios';
import { toast } from 'react-toastify';
import { Path } from '../../app/const';

const toastError = () => toast.error('Something went wrong');

export const getVenues = (setter: any, setLoading: any) => {
  setLoading(true);
  return axios
    .get(`/creator/my/venues/`)
    .then((res) => (res ? setter(res?.data) : toastError()))
    .catch((error) => toast.error(error?.message))
    .finally(() => setLoading(false));
};

export const createVenue = () => {
  return axios
    .post(`/creator/my/create-venue/`, {})
    .then((res) => (res?.data?.detail === 'success' ? window.Telegram.WebApp.close() : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const getVenue = (id: string | number, setter: any) => {
  return axios
    .get(`/creator/my/venues/${id}/`)
    .then((res) => (res ? setter(res?.data) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const getVenueByUser = (id: string | number, setter: any) => {
  return axios
    .get(`/creator/public/venues/${id}/`)
    .then((res) => (res ? setter(res?.data) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const updateVenue = (id: string | number, data: object, setLoading: any, setUpdatedData: any, redirect?: any) => {
  setLoading(true);
  return axios
    .patch(`/creator/my/venues/${id}/`, data)
    .then((res) => {
      if (res) {
        setUpdatedData(res?.data);
        redirect && redirect();
        toast.success('Successfully saved');
      } else {
        toastError();
      }
    })
    .catch((error) => toast.error(error?.message))
    .finally(() => setLoading(false));
};

export const updateVenueLocation = (id: string | undefined) => {
  if (id) {
    return axios
      .post(`/creator/my/venues/${id}/location/`, {})
      .then((res) => (res?.data?.detail === 'success' ? window.Telegram.WebApp.close() : toastError()))
      .catch((error) => toast.error(error?.message));
  }
};

export const deleteVenue = (id: string | number, setLoading: any, redirect: any) => {
  setLoading(true);
  return axios
    .delete(`/creator/my/venues/${id}/`)
    .then((res) => (res ? toast.success('Successfully removed') : toastError()))
    .catch((error) => toast.error(error?.message))
    .finally(() => {
      setLoading(false);
      redirect(Path.VenuesCreator);
    });
};

export const getVenueImages = (id: string | number, setter: any) => {
  return axios
    .get(`/creator/my/venues/${id}/images/`)
    .then((res) => (res ? setter(res?.data) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const postVenueImage = (id: string | number, data: object) => {
  return axios
    .post(`/creator/my/venues/${id}/images/`, data)
    .then((res) => (res ? toast.success(res?.data?.detail) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const deleteVenueImage = (id: string | number, imageId: string) => {
  return axios
    .delete(`/creator/my/venues/${id}/images/${imageId}/`)
    .then((res) => (res ? toast.success('Successfully deleted') : toastError()))
    .catch((error) => toast.error(error?.message));
};
