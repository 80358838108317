import {useEffect, useState, useRef} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {ReactComponent as UnreadIcon} from '../../../assets/icons/sent.svg';
import {ReactComponent as SendBtnIcon} from '../../../assets/icons/send-btn.svg';
import {ReactComponent as ReadIcon} from '../../../assets/icons/read.svg';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow_down.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow.svg';
import {ReactComponent as MessageIcon} from '../../../assets/icons/message-2.svg';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import useWebSocket from 'react-use-websocket';
import {getSingleRoom, selectRooms, loadNewMessages, addLastMessage} from '../../../features/Chats/chatSlice';

import {selectHash, selectProfileType, setBottomBoxForIphone} from '../../../app/profileSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './Chat.module.scss';
import {Path} from '../../../app/const';
import {API_BASE_URL_WS} from '../../../app/const';
import {format} from 'date-fns';
import anonymous from '../../../assets/images/anonym.png';
import SkeletonChatContent from '../../../shared/SkeletonChatContent';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check-2.svg';

export default function Chat() {
  const dispatch = useAppDispatch();
  const {
    singleRoom: {user1, user2, results, next, previous},
    rootPath,
    loading,
    preloadChat
  } = useAppSelector(selectRooms);
  const {t} = useTranslation();

  const [recent_messages, setRecentMessages] = useState<number>(0);

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const hash = useAppSelector(selectHash);
  const profile_type = useAppSelector(selectProfileType);
  const input = useRef<HTMLInputElement>(null);
  const [allMessageWasRead, setAllMessageWasRead] = useState(false);

  const state = useAppSelector((state) => state);

  const id = pathname.split('/')[2];

  const socketUrl = `${API_BASE_URL_WS}/chat/private/`;

  const [messageBody, setMessage] = useState({
    type: 'private_message',
    data: {
      to_room: id,
      message: ''
    }
  });

  //CHECK WHO IS THE RESPONDENT

  let respondent = user1?.is_me ? user2 : user1;

  const {lastJsonMessage, sendJsonMessage, getWebSocket} = useWebSocket(socketUrl, {
    onOpen: () => console.log('connected chat'),
    onMessage: (e) => {
      //console.log(JSON.parse(e.data));
    },
    shouldReconnect: (closeEvent) => true,
    share: true,
    queryParams: {
      hash: hash ? hash : ''
    }
  });

  useEffect(() => {
    profile_type === 'default' && dispatch(getSingleRoom(+id));
  }, [profile_type]);

  //get first child of chat
  const firstChild = document.querySelector(`.infinite-scroll-component  > p:first-child`);

  //scroll to top function for Safari
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  //scroll to bottom Chat function

  const scrollToBottom = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');

    scrollableDiv?.scrollTo(0, scrollableDiv.scrollHeight);
  };

  //enable screen keyboard on mobile devices function
  const enableKeyboard = () => {
    input.current?.focus();
  };

  //Function Just for Iphones
  const scrollToLastMessage = () => {
    setRecentMessages(0);
    scrollToBottom();
  };

  useEffect(() => {
    dispatch(addLastMessage(lastJsonMessage));

    if (lastJsonMessage !== null) {
      // last message type
      let type = Object.values(lastJsonMessage)[0];

      //Don't count your messages and default chat_read
      type !== 'chat_read' && type !== 'echo' && setRecentMessages((previous) => previous + 1);
      type === 'chat_read' && setAllMessageWasRead(true);
      scrollToTop();

      //last message data
      let lastMessage: any = Object.values(lastJsonMessage)[1];
      sendJsonMessage({
        type: 'mark_read',
        data: {
          message_id: lastMessage.id
        }
      });
    }
  }, [lastJsonMessage]);

  let isInitiallyVisible = false;

  const [isKeyboardVisible, setKeyboardVisible] = useState(isInitiallyVisible);

  useEffect(() => {
    window.visualViewport &&
      window.visualViewport.addEventListener('resize', () => {
        setKeyboardVisible(!isKeyboardVisible);
      });
  }, [isKeyboardVisible]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {respondent && (
        <header className={styles.header}>
          <button
            className={`${styles.back} hover`}
            type='button'
            aria-label='Back button'
            onClick={() => (rootPath ? navigate(rootPath) : navigate(Path.Chats))}
          >
            <ArrowIcon className={styles.arrow} />
          </button>
          <Link className={styles.user} to={`${Path.UserProfile}/${respondent.id}`}>
            <img
              src={respondent.picture || anonymous}
              width='32px'
              height='32px'
              alt={respondent.first_name || undefined}
            />
            <h1 className='truncated'>{respondent.first_name ? respondent.first_name : t('CHAT.anonymous')}</h1>
            {respondent?.is_verified && <CheckIcon />}
          </Link>
        </header>
      )}

      <div id='scrollableDiv' className={results?.length === 0 ? styles.main_empty : styles.main}>
        {
          <InfiniteScroll
            dataLength={results?.length}
            next={() => {
              dispatch(loadNewMessages(next));
            }}
            endMessage={
              previous && (
                <p style={{textAlign: 'center'}}>
                  <b>There are no messages more...</b>
                </p>
              )
            }
            style={{display: 'flex', flexDirection: 'column-reverse'}}
            className={styles.scroll__block}
            inverse={true}
            hasMore={!!next}
            loader={preloadChat !== 'succeeded' && <h4>{t('CHAT.loading')}</h4>}
            scrollableTarget='scrollableDiv'
            onScroll={(e) => {
              //Clear state recent messages when scrolled to bottom
              const target = e.target as Element;
              target.scrollTop <= 20 && setRecentMessages(0);
            }}
          >
            {results?.length === 0 && loading === 'succeeded' && (
              <div className={styles.noMessages}>
                <MessageIcon />
                <p>{t('CHAT.send')}</p>
              </div>
            )}

            {loading !== 'succeeded' && results?.length === 0 && <SkeletonChatContent />}

            {results?.length !== 0 && (
              <>
                {results.map((message: any, index: any) => (
                  <>
                    {index !== 0
                      ? format(new Date(message.create_date), 'dd') !==
                          format(new Date(results[index - 1]?.create_date), 'dd') && (
                          <time className={styles.time}>
                            {format(new Date(results[index - 1]?.create_date), 'MMMM dd')}
                          </time>
                        )
                      : ''}

                    <p key={index} className={`${styles.message} ${!message.is_mine && styles.incoming}`}>
                      {message.text}
                      <span className={styles.messageTime}>
                        <time> {format(new Date(message.create_date), 'HH:mm')}</time>

                        {message.is_mine ? message.unread && allMessageWasRead ? <UnreadIcon /> : <ReadIcon /> : ''}
                      </span>
                    </p>
                  </>
                ))}
              </>
            )}

            {results?.length !== 0 && (
              <time className={styles.time}>{format(new Date(results[0]?.create_date), 'MMMM dd')}</time>
            )}
          </InfiniteScroll>
        }
        {recent_messages > 0 && navigator.platform === 'iPhone' && (
          <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              scrollToLastMessage();
            }}
            className={styles.down__btn}
          >
            <span className={styles.down__quantity}>{recent_messages < 10 ? recent_messages : '9+'}</span>
            <ArrowDown />
          </button>
        )}
      </div>

      <form className={styles.footer}>
        <input
          onChange={(e) => {
            setMessage({...messageBody, data: {...messageBody.data, message: e.target.value}});
            navigator.platform === 'iPhone' && scrollToBottom();
            dispatch(setBottomBoxForIphone(true));
          }}
          ref={input}
          onFocus={() => {
            dispatch(setBottomBoxForIphone(true));
            scrollToBottom();
          }}
          onBlur={() => {
            navigator.platform === 'iPhone' && scrollToTop();
            scrollToBottom();
            dispatch(setBottomBoxForIphone(false));
          }}
          autoFocus
          type='text'
          placeholder={'Type a message...'}
        />
        <button
          aria-label='Send message'
          disabled={messageBody.data.message === ''}
          type='submit'
          onClick={(e) => {
            e.preventDefault();
            sendJsonMessage(messageBody);
            setMessage({
              type: 'private_message',
              data: {
                to_room: id,
                message: ''
              }
            });
            enableKeyboard();

            navigator.platform === 'iPhone' && scrollToTop();
            //Reset message
            Object.values(input)[0].value = '';
          }}
        >
          <SendBtnIcon />
        </button>
      </form>

      {isKeyboardVisible && navigator.platform === 'iPhone' && (
        <div className='up_content' style={{marginTop: 310}}></div>
      )}
    </>
  );
}
