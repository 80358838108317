import PageWrap from '../../../layout/PageWrap';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import btnStyles from '../../../shared/Button/Button.module.scss';
import styles from './ChangePhoto.module.scss';
import {API_BASE_URL} from '../../../app/const';
import {
  selectProfileUser,
  selectProfileCreator,
  selectProfileType,
  patchAvatar,
  patchAvatarCreator
} from '../../../app/profileSlice';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

export default function ChangePhoto() {
  const dispatch = useAppDispatch();
  const {profile} = useAppSelector(selectProfileUser);
  const creator = useAppSelector(selectProfileCreator);
  const profile_type = useAppSelector(selectProfileType);
  const {t} = useTranslation();
  const formData = new FormData();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

  const toastText = t('BOTLANGUAGE.photoError')

  const sendFile = (file: any) => {
    let fileItem = file[0];
    if (fileItem) {
      if (validImageTypes.includes(fileItem.type)) {
        formData.append('image', fileItem);
      } else {
        toast.error(toastText);
      }
    }
    dispatch(profile_type === 'default' ? patchAvatar(formData) : patchAvatarCreator(formData));
  };

  return (
    <PageWrap mainClass={styles.root} title={t('BOTLANGUAGE.change')} goBack='/profile'>
      <p>
        {profile_type === 'default'
          ? profile?.picture
            ? t('BOTLANGUAGE.heres')
            : t('BOTLANGUAGE.you')
          : creator?.picture
          ? t('BOTLANGUAGE.heres2')
          : t('BOTLANGUAGE.you2')}
      </p>
      <div className={styles.photoWrap}>
        {profile_type === 'default'
          ? profile?.picture && (
              <img
                className={styles.photo}
                src={`${API_BASE_URL}${profile?.picture}`}
                width='343px'
                height='343px'
                alt='Profile'
              />
            )
          : creator?.picture && (
              <img className={styles.photo} src={creator?.picture} width='343px' height='343px' alt='Profile' />
            )}
      </div>

      <label className={`${btnStyles.root} ${styles.btn}`}>
        {profile_type === 'default'
          ? profile?.picture
            ? t('BOTLANGUAGE.change2')
            : t('BOTLANGUAGE.upload')
          : creator?.picture
          ? t('BOTLANGUAGE.change2')
          : t('BOTLANGUAGE.upload')}

        <input className='visuallyHidden' type='file' accept='image/*' onChange={(e) => sendFile(e.target?.files)} />
      </label>
    </PageWrap>
  );
}
