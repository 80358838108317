import {useEffect, useState} from 'react';
import PageWrap from '../../layout/PageWrap';
import ChatPreview from './ChatPreview';
import noChatsImg from '../../assets/images/no-chats.png';
import styles from './Chats.module.scss';
import {selectRooms, getRooms} from '../../features/Chats/chatSlice';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectProfileUser} from '../../app/profileSlice';
import SkeletonChat from '../../shared/SkeletonChat';
import {useTranslation} from 'react-i18next';

export default function Chats() {
  const dispatch = useAppDispatch();
  const {rooms, loading, activeRooms, chatRequestUsed} = useAppSelector(selectRooms);
  const [showPicture, setShowPicture] = useState(false);
  const {is_anonymous} = useAppSelector(selectProfileUser);
  const [showPopup, setShowPopup] = useState(true);
  let showNote = localStorage.getItem('do_not_show_message');
  const {t} = useTranslation();

  useEffect(() => {
    !is_anonymous && !chatRequestUsed && dispatch(getRooms());
  }, [is_anonymous]);

  const hideMessage = () => {
    localStorage.setItem('do_not_show_message', 'true');
    setShowPopup(false);
  };

  useEffect(() => {
    setShowPicture(true);
    dispatch(getRooms());
  }, []);

  return (
    <PageWrap mainClass={styles.root} goBack='/' title='Chats'>
      <>
        {showNote !== 'true' && showPopup && (
          <p className='mb24'>
            {t('CHATS.please')}{' '}
            <button className={`${styles.noteBtn} hover`} type='button' onClick={hideMessage}>
              {t('CHATS.dont')}
            </button>
          </p>
        )}

        {loading === 'loading' &&
          !chatRequestUsed &&
          activeRooms === 0 &&
          [...Array(3)].map((item) => <SkeletonChat key={item} />)}

        {activeRooms > 0 &&
          rooms.map((chat: any, index: any) => (
            <ChatPreview id={index} loading={loading} key={index} className='mb16' {...chat} />
          ))}
      </>

      {chatRequestUsed && activeRooms === 0 && (
        <>
          <img className={styles.noChatsImg} src={noChatsImg} alt='No chats' />
          <p>
            <b>{t('CHATS.you')}</b>
            <br />
            {t('CHATS.make')}
          </p>
        </>
      )}
    </PageWrap>
  );
}
