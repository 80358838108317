import Button from '../../../shared/Button';
import venueImg from '../../../assets/images/venue.png';
import styles from './NoVenues.module.scss';
import {useTranslation} from 'react-i18next';


interface NoVenuesProps {
  onClick: () => void;
}

export default function NoVenues({onClick}: NoVenuesProps) {
  const {t} = useTranslation();
  return (
    <div className={styles.root}>
      <img className={styles.img} src={venueImg} width='343px' height='259px' alt='Venue' />
      <p className={styles.desc}>
        <b>{t("NOVENUES.you")}</b>
        <br />
        {t("NOVENUES.lets")}
      </p>
      <Button className={styles.btn} onClick={onClick}>
        {` ${t("VENUES.add")} +`}
   
      </Button>
    </div>
  );
}
