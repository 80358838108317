import {useEffect, useState} from 'react';
import PageWrap from '../../layout/PageWrap';
import MissingData from '../../shared/MissingData';
import RangeSlider from '../../shared/RangeSlider';
import Button from '../../shared/Button';
import styles from './MeetSomeone.module.scss';
import WantedInterests from '../../shared/WantedInterests/WantedInterests';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectedInterestNames} from '../../features/AttendTheEvent/eventsSlice';
import {getCandidates, setSearchParams} from './searchSlice';
import {selectProfileUser} from '../../app/profileSlice';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import SelectButtons from '../../shared/SelectButtons';
import RangeSliderSingle from '../../shared/RangeSliderSingle';
import {selectLanguage} from '../../app/profileSlice';
import {useTranslation} from 'react-i18next';

export default function MeetSomeone() {
  const state = useAppSelector((state) => state);
  const {profile} = useAppSelector(selectProfileUser);
  const interestsArr = useAppSelector(selectedInterestNames);
  const navigate = useNavigate();
  const [missingData, setMissingData] = useState(false);
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);

  const skipMessage = () => setMissingData(false);

  useEffect(() => {
    if (!profile) {
      setMissingData(true);
    }
  }, []);

  const toastText = t('PROFILE.please');

  const returnToMain = () => {
    navigate('/');
    toast.error(toastText, {
      toastId: 'error-data'
    });
  };

  useEffect(() => {
    profile.is_anonymous && returnToMain();
  }, [state]);

  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(60);
  const [gender, setGender] = useState('none');
  const [selectedRadius, setSelectedRadius] = useState('5');
  const [enteredRadius, setenteredRadius] = useState('');
  //const handleSelectedRadius = ({target: {value}}: any) => setSelectedRadius(value);
  const radius = enteredRadius ? enteredRadius : selectedRadius;
  const genderButtons = [
    {id: 1, value: 'male', label: t('EVENTPEOPLE.man')},
    {id: 2, value: 'female', label: t('EVENTPEOPLE.woman')},
    {id: 3, value: 'none', label: t('EVENTPEOPLE.doesnt')}
  ];

  const dispatch = useAppDispatch();

  return (
    <PageWrap mainClass={styles.root} title={t('MEETSOMEONE.meet')} goBack='/'>
      {missingData && <MissingData onClick={skipMessage} />}

      {!missingData && (
        <>
          <p className='my8'>{t('MEETSOMEONE.who')}</p>
          <SelectButtons
            options={genderButtons}
            defaultValue={'none'}
            onChange={setGender}
            title={t('MEETSOMEONE.gender')}
          />
          <RangeSlider min={16} max={60} setMinAge={setMinAge} setMaxAge={setMaxAge} />
          {/* <label className='inputWrap mb16'>
            <span className='innerLabel'>Search radius</span>
            <select defaultValue={selectedRadius} onChange={handleSelectedRadius}>
              {radiusOptions.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </label>
          {selectedRadius === 'custom' && (
            <>
              <label className='inputWrap'>
                <input
                  type='number'
                  min={0}
                  max={50}
                  onChange={(e) => setenteredRadius(e.target.value)}
                  placeholder='Type here...'
                />
                <span className={styles.inputHelper}>km</span>
              </label>
              <span className='caption'>Max. 50 km</span>
            </>
          )} */}

          <RangeSliderSingle
            language={language}
            title={t('ATTENDTHEEVENTCHANGEPARAMS.search')}
            setFunction={setenteredRadius}
          />
          <div className={`${styles.select} mb32`}>
            <span className={styles.selectLabel}>{t('INTERESTSEARCHRESULTS.wanted')}</span>

            <WantedInterests />
          </div>
          <Button
            className={styles.btn}
            // disabled={interestsArr.length === 0 || !radius ? true : false}
            onClick={() => {
              dispatch(
                getCandidates({
                  gender: gender === 'none' ? null : gender,
                  interests: interestsArr,
                  age_from: minAge,
                  radius: +radius * 1000,
                  age_to: maxAge
                })
              ).then((res: any) => {
                if (res.type && res.type.includes('fulfilled')) {
                  window.Telegram.WebApp.close();
                }
              });
              dispatch(
                setSearchParams({
                  gender: gender === 'none' ? null : gender,
                  interests: interestsArr,
                  radius: radius,
                  age_from: +minAge,
                  age_to: +maxAge
                })
              );
            }}
          >
            {t('BROWSEINTERESTS.send')}
          </Button>
        </>
      )}
    </PageWrap>
  );
}
