import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { API_BASE_URL } from '../../app/const';

export interface Interest {
  id: number;
  name: string;
}
export interface Profile {
  id: number;
  first_name: string;
  picture: string;
  gender: string;
  bio: string;
  birth_date: string;
  city: string;
}

export interface InterestedPeoples {
  interest: string
  total: number
}
export interface interestsInterface {
  loading: string;
  error: string | undefined;
  params: {
    gender: 'male' | 'female' | null;
    age_from: number;
    age_to: number;
    interests: [];
  };
  candidates: Profile[];
  interestsList: {
    wanted_interests: InterestedPeoples[] | []
    other_interests: InterestedPeoples[] | []
  } | null;
}

export interface Root {
  profiles: Profile[];
}



export const initialState: interestsInterface = {
  loading: '',
  error: '',
  params: {
    gender: null,
    age_from: 18,
    age_to: 60,
    interests: []
  },
  candidates: [],
  interestsList: null
};

export const fetchPersonalData = createAsyncThunk('GET_PERSONAL_INFO', async () => {
  const response = await axios.get(`${API_BASE_URL}/profiles/my/`,);
  return response.data;
});

export const getCandidates = createAsyncThunk('GET_CANDIDATES', async (body: any) => {
  const response = await axios.post(`${API_BASE_URL}/meet-someone/`, body,);
  return response.data;
});

export const getCandidatesList = createAsyncThunk('GET_CANDIDATES_LIST', async (uuid: any) => {
  const response = await axios.get(`${API_BASE_URL}/meet-someone/${uuid}/`);
  return response.data;
});

export const getBrowseInterests = createAsyncThunk('GET_BROWSE_INTEREST', async (body: any) => {
  const response = await axios.post(`${API_BASE_URL}/searches/browse/`, body);
  return response.data;
});


export const getListOfInterests = createAsyncThunk('GET_LIST_OF_INTERESTED', async (uuid: any) => {
  const response = await axios.get(`${API_BASE_URL}/searches/browse/${uuid}/list/`);
  return response.data;
});





const interestsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSearchParams: (state: any, action: PayloadAction<{} | null>) => {
      state.params = action.payload;
    }
  },
  extraReducers(builder) {
    builder

      //GET CANDIDATES INFO REQUEST
      .addCase(getCandidates.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getCandidates.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        // state.candidates = action.payload.profiles
      })
      .addCase(getCandidates.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET CANDIDATES LIST REQUEST
      .addCase(getCandidatesList.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getCandidatesList.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload.profiles;
      })
      .addCase(getCandidatesList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET BROWSE INTERESTS REQUEST

      .addCase(getBrowseInterests.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getBrowseInterests.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload;
      }
      )
      .addCase(getBrowseInterests.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET LIST OF INTERESTED 
      .addCase(getListOfInterests.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(getListOfInterests.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.interestsList = action.payload;
      }
      )
      .addCase(getListOfInterests.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )

  }
});

export const selectCandidates = (state: RootState) => state.candidates;
export const selectInterestsList = (state: RootState) => state.interests.interestsList;

export const { setSearchParams } = interestsSlice.actions;

export default interestsSlice.reducer;
