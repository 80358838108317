import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useAppSelector} from '../../../app/hooks';
import {Path, GOOGLE_MAP_KEY} from '../../../app/const';
import {singleEvent} from '../../AttendTheEvent/eventsSlice';
import PageWrap from '../../../layout/PageWrap';
import placeholderImg from '../../../assets/icons/placeholder.svg';
import {ReactComponent as StarIcon} from '../../../assets/icons/star.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import 'swiper/css';
import styles from './VenueProfile.module.scss';

export default function VenueProfile() {
  const navigate = useNavigate();
  const eventData = useAppSelector(singleEvent);
  const [venue, setVenue] = useState({
    images: [],
    name: '',
    rate: 0,
    description: '',
    address: '',
    latitude: 0,
    longitude: 0
  });
  const {images, name, rate, description, address, latitude, longitude} = venue;

  useEffect(() => {
    eventData ? setVenue(eventData?.venue) : navigate(Path.Root);
  }, []);

  return (
    <PageWrap mainClass={styles.root} title='Venue profile' goBack={-1}>
      <Swiper className={styles.slider} modules={[A11y]} spaceBetween={8}>
        {images?.length ? (
          images.map((image, index) => (
            <SwiperSlide key={index}>
              <img className={styles.img} src={image} alt='Venue' />
            </SwiperSlide>
          ))
        ) : (
          <img className={styles.img} src={placeholderImg} alt='Placeholder' />
        )}
      </Swiper>
      <div className={styles.name}>
        <span className='title truncated'>{name ? name : '-'}</span>
        {rate ? (
          <span className={styles.rating}>
            <StarIcon />
            {rate}
          </span>
        ) : null}
      </div>

      <p className='mb16'>{description ? description : '-'}</p>

      <div className={styles.location}>
        <LocationIcon /> <span className='truncated details'>{address ? address : '-'}</span>
      </div>

      {latitude && longitude ? (
        <a href={`https://www.google.com.ua/maps/@${latitude},${longitude},18z`}>
          <img
            className={styles.map}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=18&size=686x518&key=${GOOGLE_MAP_KEY}`}
            alt='Map'
          />
        </a>
      ) : null}
    </PageWrap>
  );
}
