import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { API_BASE_URL } from '../../app/const';
export interface Room {
  id: number;
  user: User;
  last_message: any;
  unread_count: number;
}

export interface User {
  id: number;
  first_name: string;
  picture: string;
  is_verified:boolean
}

export interface LastMessage {
  id: number;
  text: string;
  create_date: number | Date;
  unread: boolean;
}

export interface Chat {
  next: any;
  previous: any;
  user1: User1 | null;
  user2: User2 | null;

  results: Result[];
}

export interface User1 {
  id: number;
  first_name: string;
  picture: string;
  is_me: boolean;
  is_verified:boolean;
}

export interface User2 {
  id: number | null;
  first_name: string | null;
  picture: string | null;
  is_me: boolean | null;
  is_verified:boolean;
}

export interface Result {
  id: number;
  text: string;
  create_date: string;
  is_mine: boolean;
  author: number;
  room_id: number;
  unread: boolean;
}

export interface chatInterface {
  loading: string | null;
  preloadChat: string | null;
  error: string | undefined;
  total_unread: number;
  rooms: Room[] | any;
  activeRooms: number;
  singleRoom: Chat;
  rootPath: string;
  isPollingStarted: boolean;
  chatRequestUsed: boolean;
}

export const initialState: chatInterface = {

  loading: null,
  preloadChat: null,
  activeRooms: 0,
  total_unread: 0,
  error: '',
  rooms: [],
  isPollingStarted: false,
  chatRequestUsed: false,
  rootPath: "",
  singleRoom: {
    next: null,
    previous: null,
    user1: null,
    user2: null,
    results: []
  }
};

export const getRooms = createAsyncThunk('GET_ROOMS', async () => {
  const response = await axios.get(`${API_BASE_URL}/chat/room/`);
  return response.data;
});

export const createRoom = createAsyncThunk('CREATE__ROOM', async (body: any) => {
  const response = await axios.post(`${API_BASE_URL}/chat/start/`, body);
  return response.data;
});

export const getSingleRoom = createAsyncThunk('GET_SINGLE_ROOM', async (room_id: number) => {
  const response = await axios.get(`${API_BASE_URL}/chat/message/?room_id=${room_id}`);
  return response.data;
});

export const loadNewMessages = createAsyncThunk('LOAD_NEW_MESSAGES', async (next__link: string) => {
  const response = await axios.get(next__link);
  return response.data;
});

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addLastMessage: (state: any, action: PayloadAction<any>) => {
      if (action.payload !== null) {
        if (action.payload.type === 'private_message' || action.payload.type === 'echo') {
          let newMessage = {
            id: +action.payload.data.id,

            text: action.payload.data.text,
            create_date: action.payload.data.create_date,
            is_mine: action.payload.data.author.is_me,
            author: +action.payload.data.sender_id,
            room_id: +action.payload.data.room_id,
            unread: action.payload.data.author.is_me ? true : false
          };
          state.singleRoom.results.unshift(newMessage);
        }
      }
    },
    setStartedPolling: (state: any, action: PayloadAction<boolean>) => {
      state.isPollingStarted = action.payload;
    },
    setRootPath: (state: any, action: PayloadAction<string>) => {
      state.rootPath = action.payload;
    }
  },
  extraReducers(builder) {
    builder

      //GET ALL ROOMS
      .addCase(getRooms.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getRooms.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.rooms = action.payload;
        state.activeRooms = action.payload.reduce((acc: any, cur: any) => {
          return acc + !!cur.last_message;
        }, 0);
        state.chatRequestUsed = true;
        state.total_unread = action.payload.reduce((acc: any, cur: any) => {
          return acc + cur.unread_count;
        }, 0);
      })
      .addCase(getRooms.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //CREATE ROOM WITH USER

      .addCase(createRoom.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(createRoom.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET SINGLE ROOM - CHAT WITH USER

      .addCase(getSingleRoom.pending, (state, action) => {
        state.loading = 'loading';
        state.singleRoom = {
          next: null,
          previous: null,
          user1: null,
          user2: null,
          results: []
        };
      })
      .addCase(getSingleRoom.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.singleRoom = action.payload;
      })
      .addCase(getSingleRoom.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //LOAD NEW MESSAGES AND CONCAATENATE TO EXISTING MESSAGES

      .addCase(loadNewMessages.pending, (state, action) => {
        state.preloadChat = 'loading';
      })
      .addCase(loadNewMessages.fulfilled, (state, action) => {
        state.preloadChat = 'succeeded';
        state.singleRoom.results = state.singleRoom.results.concat(action.payload.results);
        state.singleRoom.next = action.payload.next;
      })
      .addCase(loadNewMessages.rejected, (state, action) => {
        state.preloadChat = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectRooms = (state: RootState) => state.chat;

export const { addLastMessage, setStartedPolling, setRootPath } = chatSlice.actions;

export default chatSlice.reducer;
