import {Path} from '../../app/const';
import {useState} from 'react';
import Button from '../Button';
import creator from '../../assets/images/creator.png';
import user from '../../assets/images/usermode.png';
import styles from './CreatorMode.module.scss';
import PageWrap from '../../layout/PageWrap';
import {switchToCreator, switchToUser, selectProfileType, selectHash} from '../../app/profileSlice';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {useTranslation} from 'react-i18next';

export default function CreatorMode() {
  const dispatch = useAppDispatch();
  const profile_type = useAppSelector(selectProfileType);
  const hash = useAppSelector(selectHash);
  const {t} = useTranslation();
  return (
    <PageWrap
      mainClass={styles.root}
      title={profile_type === 'default' ? 'Creator mode' : 'User mode'}
      goBack={Path.Profile}
      // onClick={select && closeSelect}
    >
      <div className={styles.root}>
        <img
          className={styles.img}
          src={profile_type === 'default' ? creator : user}
          width='343px'
          height='259px'
          alt='Anonymous'
        />

        {profile_type === 'default' ? (
          <p className='mt-8'>
          {t('CREATORMODE.want')}
          </p>
        ) : (
          <p className='mt-8'>
            {t('CREATORMODE.want1')}
          </p>
        )}

        {profile_type === 'default' ? (
          <p className='mt-8'>{t('CREATORMODE.you')}</p>
        ) : (
          <p className='mt-8'>{t('CREATORMODE.you1')}</p>
        )}

        <Button
          className={styles.btn}
          onClick={() =>
            dispatch(profile_type === 'default' ? switchToCreator() : switchToUser()).then((res) => {
              if (res.type && res.type.includes('fulfilled')) {
                window.Telegram.WebApp.close();
              }
            })
          }
        >
          {t('CREATORMODE.switch')}
        </Button>
      </div>
    </PageWrap>
  );
}
