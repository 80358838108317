import {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import PageWrap from '../../../layout/PageWrap';
import TagGroup from '../../../shared/TagGroup';
import PeopleSlider from '../../../shared/PeopleSlider';
import {ReactComponent as HeartIcon} from '../../../assets/icons/heart.svg';
import {ReactComponent as HeartOutlinedIcon} from '../../../assets/icons/heart-outlined.svg';
import {useLocation} from 'react-router-dom';
import {getListOfInterests, selectInterestsList} from '../interestsSlice';
import {getListOfInteredPeople} from '../../../features/MeetSomeone/searchSlice';
import 'swiper/css';
import styles from './InterestSearchResults.module.scss';
import {selectHash} from '../../../app/profileSlice';
import {useTranslation} from 'react-i18next';

export default function InterestSearchResults() {
  const [inner, setInner] = useState('');
  const backToResults = () => setInner('');
  const dispatch = useAppDispatch();
  const interestData = useAppSelector(selectInterestsList);
  const hash = useAppSelector(selectHash);

  const [like, setLike] = useState(false);
  const handleLike = () => setLike((prev) => !prev);
  const {t} = useTranslation();

  const {search} = useLocation();
  const uuid = search.split('=')[1];

  useEffect(() => {
    hash && dispatch(getListOfInterests(uuid));
  }, [hash]);

  useEffect(() => {
    uuid && inner && dispatch(getListOfInteredPeople({uuid: uuid, interest: inner}));
  }, [inner]);

  return (
    <PageWrap
      mainClass={styles.root}
      title={!inner ? t('INTERESTSEARCHRESULTS.search') : inner}
      onClick={inner && backToResults}
    >
      {!inner ? (
        <div className='p16'>
          {interestData?.wanted_interests && interestData?.wanted_interests.length > 0 && (
            <TagGroup
              data={interestData?.wanted_interests}
              title={t('INTERESTSEARCHRESULTS.wanted')}
              onTagClick={setInner}
            />
          )}

          {interestData?.other_interests && interestData?.other_interests.length > 0 && (
            <TagGroup
              data={interestData?.other_interests}
              title={t('INTERESTSEARCHRESULTS.other')}
              onTagClick={setInner}
            />
          )}
        </div>
      ) : (
        <>
          <button
            className={styles.like}
            type='button'
            aria-label={`${like ? t('INTERESTSEARCHRESULTS.dislike') : t('INTERESTSEARCHRESULTS.like')} ${t(
              'INTERESTSEARCHRESULTS.interest'
            )}`}
            onClick={handleLike}
          >
            {like && <HeartIcon />}
            {!like && <HeartOutlinedIcon />}
          </button>
          <PeopleSlider />
        </>
      )}
    </PageWrap>
  );
}
