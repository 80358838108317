import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from './locales/en.json';
import translation_ee from './locales/ee.json';
import translation_ua from './locales/ua.json';
import translation_ru from './locales/ru.json';
import LanguageDetector from 'i18next-browser-languagedetector';


const resources = {
    en: {
        translation: translation_en,
    },
    ee: {
        translation: translation_ee,
    },
    ua: {
        translation: translation_ua,
    },
    ru: {
        translation: translation_ru,
    },
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });