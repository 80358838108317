import {useState, useRef, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './EditInfo.module.scss';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {scrollToElement} from '../../../app/const';
import {
  selectProfileUser,
  patchPersonalData,
  patchPersonalDataCreator,
  selectProfileType,
  fetchPersonalData,
  fetchPersonalDataCreator,
  selectProfileCreator,
  selectHash
} from '../../../app/profileSlice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

export default function EditInfo() {
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState(false);
  const {profile, interests} = useAppSelector(selectProfileUser);
  const creator = useAppSelector(selectProfileCreator);
  const hash = useAppSelector(selectHash);
  const profile_type = useAppSelector(selectProfileType);

  const [profileInfo, setProfileInfo] = useState<any>('');
  let [interestsInfo, setInterestsInfo] = useState<any>([]);
  const {t} = useTranslation();
  const toastText = t('EDITINFO.yourdata')

  // split string into array of interests

  let arrayOfInterests = interestsInfo.toString().split(',');
  let interestsArr: any = [];

  interests &&
    interests.map((el: any) => {
      interestsArr.push(el.name);
    });

  const mainPatchFunction = profile_type === 'default' ? patchPersonalData : patchPersonalDataCreator;
  const mainFetchFunction = profile_type === 'default' ? fetchPersonalData : fetchPersonalDataCreator;

  //Input refs
  const nameRef = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const interestsRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interestsArr: any = [];
    interests && interests.map((el: any) => interestsArr.push(el.name));
    setInterestsInfo(interestsArr.toString().split(','));
  }, [interests]);

  return (
    <PageWrap
      mainClass={styles.root}
      title={profile_type === 'default' ? t('EDITINFO.edit') : t('EDITINFO.edit2')}
      goBack='/profile'
      stateClear={() => {
        setProfileInfo('');
        setInterestsInfo('');
      }}
    >
      {profile_type === 'creator' && <p className='mb-16'>{t('EDITINFO.personal')}</p>}

      {profile_type === 'default' ? (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.name')}</span>

          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, first_name: e?.target?.value});
              setTouched(true);
            }}
            ref={nameRef}
            onFocus={() => scrollToElement(nameRef)}
            defaultValue={profileInfo.first_name ? profileInfo.first_name : profile?.first_name}
            type='text'
            placeholder={t('EDITINFO.your')}
          />
        </label>
      ) : (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.full')}</span>

          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, full_name: e?.target?.value});
              setTouched(true);
            }}
            ref={nameRef}
            onFocus={() => scrollToElement(nameRef)}
            defaultValue={profileInfo.full_name ? profileInfo.full_name : creator?.full_name}
            type='text'
            placeholder={t('EDITINFO.your2')}
          />
        </label>
      )}

      {profile_type === 'default' && (
        <label className='inputWrap sex'>
          <span className='innerLabel'>{t('EDITINFO.gender')}</span>
          <select
            onChange={(e) => {
              setProfileInfo({...profileInfo, gender: e.target.value});
              setTouched(true);
            }}
            defaultValue={profileInfo.gender ? profileInfo.gender : profile?.gender}
          >
            <option value='' disabled hidden>
              {t('EDITINFO.not')}
            </option>
            <option value='male'>{t('EDITINFO.man')}</option>
            <option value='female'>{t('EDITINFO.woman')}</option>
          </select>
        </label>
      )}

      {profile_type !== 'default' && (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.phone')}</span>

          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, phone_number: e?.target?.value});
              setTouched(true);
            }}
            defaultValue={profileInfo.phone_number ? profileInfo.phone_number : creator?.phone_number}
            type='text'
            placeholder={t('EDITINFO.your3')}
          />
        </label>
      )}

      {profile_type === 'default' && (
        <>
          <label className={navigator.platform === 'iPhone' ? 'inputWrap birth-iphone' : 'inputWrap birth'}>
            <span className='innerLabel'>{t('EDITINFO.date')}</span>
            <input
              type='date'
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, birth_date: e?.target?.value});
                setTouched(true);
              }}
              defaultValue={profileInfo.birth_date ? profileInfo.birth_date : profile?.birth_date}
            />
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.bio')}</span>
            <textarea
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, bio: e?.target?.value});
                setTouched(true);
              }}
              defaultValue={profileInfo.bio ? profileInfo.bio : profile?.bio}
              ref={bioRef}
              onFocus={() => scrollToElement(bioRef)}
              placeholder={t('EDITINFO.a')}
            />
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.interests')}</span>
            <input
              onChange={(e: any) => {
                setInterestsInfo(e?.target?.value);
                setTouched(true);
              }}
              type='text'
              ref={interestsRef}
              onFocus={() => scrollToElement(interestsRef)}
              defaultValue={interestsArr.join(', ')}
              placeholder={t('EDITINFO.your4')}
            />
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.where')}</span>
            <input
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, city: e?.target?.value});
                setTouched(true);
              }}
              ref={locationRef}
              onFocus={() => scrollToElement(locationRef)}
              defaultValue={profileInfo.city ? profileInfo.city : profile?.city}
              type='text'
              placeholder={t('EDITINFO.your5')}
            />
          </label>{' '}
        </>
      )}

      {profile_type === 'creator' && (
        <>
          <p className='mt-32'>{t('EDITINFO.payment')}</p>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.card')}</span>

            <input
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, card_holder: e?.target?.value});
                setTouched(true);
              }}
              defaultValue={profileInfo.card_holder && profileInfo.card_holder}
              type='text'
              placeholder={t('EDITINFO.your6')}
            />
          </label>

          <div className={styles.row}>
            <label className='inputWrap'>
              <span className='inputLabel'>{t('EDITINFO.card2')}</span>
              <input
                onChange={(e: any) => {
                  setProfileInfo({...profileInfo, card_number: e?.target?.value});
                  setTouched(true);
                }}
                defaultValue={profileInfo.card_number ? profileInfo.first_name : creator?.card_number}
                type='text'
                placeholder={t('EDITINFO.enter')}
              />
            </label>
            <label className='inputWrap'>
              <span className='inputLabel'>{t('EDITINFO.cvv')}</span>

              <input
                onChange={(e: any) => {
                  setProfileInfo({...profileInfo, cvv: e?.target?.value});
                  setTouched(true);
                }}
                defaultValue={profileInfo.cvv ? profileInfo.cvv : profile?.cvv}
                type='text'
                placeholder={t('EDITINFO.cvv')}
              />
            </label>
          </div>
        </>
      )}

      <Button
        className={styles.btn}
        disabled={!touched}
        onClick={() =>
          hash &&
          dispatch(
            mainPatchFunction(
              profile_type === 'default'
                ? {body: {...profileInfo, interests: arrayOfInterests}, hash}
                : {profileInfo, hash}
            )
          ).then((res: any) => {
            if (res.type && res.type.includes('fulfilled')) {
              dispatch(mainFetchFunction());
              toast.success(toastText);
            }
          })
        }
      >
        {t('EDITINFO.save')}
      </Button>
    </PageWrap>
  );
}
