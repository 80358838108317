import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {GOOGLE_MAP_KEY} from '../../app/const';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectProfileUser, changeLocation} from '../../app/profileSlice';
import PageWrap from '../../layout/PageWrap';
import Button from '../../shared/Button';
import styles from './SubscriptionDetails.module.scss';
import {useTranslation} from 'react-i18next';

export default function SubscriptionDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {subscription, profile} = useAppSelector(selectProfileUser);

  useEffect(() => {
    // if (subscription?.type ==='free') navigate(Path.Root);
  });

  return (
    <PageWrap mainClass={styles.root} title={t('SUBSCRIPTIONDETAILS.subscription')}>
      {subscription?.type === 'buy' && (
        <p>
          {t('SUBSCRIPTIONDETAILS.purchased')} <b>{subscription?.address}.</b> {t('SUBSCRIPTIONDETAILS.visible')}
        </p>
      )}
      {/* {subscription?.type === 'qr' && (
        <p>
          You have scanned QR-code at <b>{profile?.city}.</b> You will be visible for others only in this location.
        </p>
      )} */}

      {subscription?.latitude && subscription?.longitude ? (
        <a href={`https://www.google.com.ua/maps/@${subscription?.latitude},${subscription?.longitude},18z`}>
          <img
            className={styles.map}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${subscription?.latitude},${subscription?.longitude}&zoom=18&size=686x518&key=${GOOGLE_MAP_KEY}`}
            alt='Map'
          />
        </a>
      ) : null}
      <p>{t('SUBSCRIPTIONDETAILS.location')}</p>

      <Button
        className={styles.btn}
        onClick={() =>
          dispatch(changeLocation()).then((res) => {
            if (res.type && res.type.includes('fulfilled')) {
              if (res.type && res.type.includes('fulfilled')) {
                window.Telegram.WebApp.close();
              }
            }
          })
        }
      >
        {t('SUBSCRIPTIONDETAILS.change')}
        {subscription?.type === 'qr' && ' for 1€'}
      </Button>
    </PageWrap>
  );
}
