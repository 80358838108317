import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Path} from '../../app/const';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectProfileUser, selectProfileCreator, selectProfileType, selectLoading} from '../../app/profileSlice';
import noPhotoIcon from '../../assets/icons/no-photo.svg';
import maleIcon from '../../assets/icons/male.svg';
import {ReactComponent as Verified} from '../../assets/icons/is_approved.svg';
import femaleIcon from '../../assets/icons/female.svg';
import {ReactComponent as MessageIcon} from '../../assets/icons/message.svg';
import styles from './Header.module.scss';
import {selectRooms, getRooms, setStartedPolling} from '../../features/Chats/chatSlice';
import {calculateAge} from '../../app/const';
import {API_BASE_URL} from '../../app/const';
import {useTranslation} from 'react-i18next';

export default function Header() {
  const dispatch = useAppDispatch();
  const {profile, is_anonymous} = useAppSelector(selectProfileUser);
  const loading = useAppSelector(selectLoading);
  const creator = useAppSelector(selectProfileCreator);
  //const state = useAppSelector((state) => state);
  const profile_type = useAppSelector(selectProfileType);
  const {total_unread, isPollingStarted} = useAppSelector(selectRooms);
  const age = calculateAge(profile?.birth_date);

  const polling = () => {
    const timer = setInterval(() => {
      dispatch(getRooms());
      dispatch(setStartedPolling(true));
    }, 3000);
    return () => clearInterval(timer);
  };

  const location = window.location.pathname;
  const {t} = useTranslation();

  useEffect(() => {
    !is_anonymous && !isPollingStarted && location === '/' && polling();
  }, [is_anonymous]);

  return (
    <header className={styles.root}>
      <Link className={styles.link} to={Path.Profile} aria-label={t('HEADER.your')}>
        {profile_type === 'default' ? (
          <img
            className={styles.photo}
            src={profile?.picture ? `${API_BASE_URL}${profile?.picture}` : noPhotoIcon}
            alt='Profile'
          />
        ) : (
          <img className={styles.photo} src={creator?.picture ? creator?.picture : noPhotoIcon} alt='Profile' />
        )}

        <div className={styles.cell}>
          {profile_type === 'default' ? (
            <h1 className={styles.title}>
              <span className='truncated row'>
                {profile && profile.first_name ? profile.first_name : t('HEADER.anonymous')}
                <span className='ml5 row'> {profile && profile?.is_verified && <Verified />}</span>
              </span>
            </h1>
          ) : (
            <h1 className={styles.title}>
              <span className='truncated'>{creator?.full_name}</span>
            </h1>
          )}

          {profile &&
            loading === 'succeeded' &&
            (profile_type === 'default' ? (
              <span className='details truncated row'>
                {profile?.gender === 'male' && <img src={maleIcon} width='14px' height='14px' alt='Man' />}
                {profile?.gender === 'female' && <img src={femaleIcon} width='14px' height='14px' alt='Woman' />}
                {profile.city && profile?.birth_date
                  ? `${profile.city}, ${age} y.o.`
                  : profile.city && !profile?.birth_date
                  ? `${profile.city}`
                  : !profile.city && profile?.birth_date
                  ? `${age} y.o.`
                  : t('HEADER.no')}
              </span>
            ) : (
              <span className='profile_type truncated'>{t('HEADER.creator')}</span>
            ))}
          {is_anonymous && profile_type === 'default' && <span className='details truncated'>{t('HEADER.no')} </span>}
        </div>
      </Link>

      {/* <Link className={styles.link} to={Path.CreatePersonalInformation} aria-label={t('HEADER.your')}>
        <div className={styles.cell}>
          <h1 className={styles.title}>
            <span className='truncated'>DONT FORGET TO DELETE</span>
          </h1>
        </div>
      </Link> */}

      {profile_type === 'default' && (
        <Link className={`${styles.chats} hover`} to={Path.Chats}>
          <MessageIcon />
          {total_unread > 0 && <span className={styles.chatsNum}>{total_unread}</span>}
        </Link>
      )}
    </header>
  );
}
