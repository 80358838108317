import React from 'react';
import {ReactComponent as LogoIcon} from '../../assets/images/Logo.svg';
import DiscoIcon from '../../assets/images/disco.png';
import styles from './QrCode.module.scss';
import {useTranslation} from 'react-i18next';

export default function QrCode() {
  const {t} = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.head_qr}><LogoIcon/></div>
      <div className={styles.qr}>
        <div className={styles.info}>
          <b>{t("QRCODE.hello")}</b>
          <span>{t("QRCODE.you")} <br/> <p>Music Disco Party</p></span>
          <img src={DiscoIcon} alt=""/>
          <div className={styles.btn}>
            <button className={styles.btn_red}>{t("QRCODE.continue")}</button>
            <button className={styles.btn_out}>{t("QRCODE.continue1")}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
